import React from 'react';
import {TriangleDownIcon, TriangleUpIcon, UploadImageIcon} from '@mgp-fe/designer/assets';
import RemoveContentButton from '@mgp-fe/designer/modules/common/ui/components/remove-content.tsx';
import {ContentImage} from '@mgp-fe/designer/state/domain';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function ImageControlButton({id, onClick, onRemove, onEdit, controlledContent}: ImageControlButtonProps) {
	return <div
		className='flex flex-col mx-auto w-32 h-32 lg:tall:w-40 lg:tall:h-40 relative select-none cursor-grab active:cursor-grabbing'>
		{!controlledContent?.file ? <TriangleUpIcon className='absolute h-full w-full z-[1]'/>
			:
			<TriangleDownIcon className='absolute h-full w-full z-[1]'/>
		}
		<div
			onClick={onClick}
			className='flex flex-col w-1/2 h-1/2 m-auto z-[2] cursor-pointer'>

			{!controlledContent?.file ?
				<>
					<UploadImageIcon className='m-auto'/>
					<h5 className='mx-auto pt-2 text-muted z-[2] text-[1rem] lg:text-[1.2rem]'>IMAGE</h5>
				</>
				:
				<div className='w-full h-full z-[4] m-auto'>
					<img
						className='object-cover h-full'
						src={controlledContent?.file ? URL.createObjectURL(controlledContent.file) : ''}
						alt='content image'
					/>
				</div>}
		</div>

		<div className='flex justify-between w-full cursor-pointer'>
			{controlledContent?.file && onEdit && <FontAwesomeIcon
				className='mr-auto mt-auto z-[3] text-muted hover:text-primary duration-300'
				onClick={event => {
					event.stopPropagation();
					onEdit && onEdit();
				}}
				icon='pen'/>}
			{controlledContent?.file && <RemoveContentButton
				id={id}
				removeCallback={onRemove}
			/>}
		</div>
	</div>;

}

interface ImageControlButtonProps {
	id: number;
	onClick: () => void;
	onRemove: () => void;
	onEdit?: () => void;
	controlledContent: ContentImage | undefined;
}