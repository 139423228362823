import React, {useContext, useEffect, useState} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {DesignerDialog} from '@mgp-fe/designer/modules/common/ui/components/designer-dialog.tsx';
import {TextDesignerDialog} from '@mgp-fe/designer/modules/common/ui/components/text-designer/text-designer-dialog.tsx';
import {ContentText} from '@mgp-fe/designer/state/domain';
import {TextControlButton} from '@mgp-fe/designer/modules/common/ui/components';

export function TextDesigner({id, modalState}: AddTextProps) {
	const designerContext = useContext(DesignerContext);
	const displayModal = useToggle({initialState: false});
	const [originalContent, setOriginalContent] = useState<ContentText | undefined | null>(null);

	const controlledContent = designerContext.content[designerContext.content.findIndex(c => c.id === id)].text;

	useEffect(() => {
		if (!originalContent && displayModal.state) {
			setOriginalContent(controlledContent);
		}
	}, [displayModal.state]);

	const onCloseHandle = (isSave?: boolean) => {
		if (isSave) {
			setOriginalContent(controlledContent);
		} else if (originalContent) {
			designerContext.dispatchContent({
				id,
				content: originalContent,
			});
		} else {
			designerContext.dispatchContent({
				id,
				content: {
					type: 'undefined',
				},
			});
		}
		displayModal.off();
		modalState(false);
	};


	return <>
		<TextControlButton
			id={id}
			onClick={() => {
				displayModal.on();
				modalState(true);
			}}
			onRemove={() => setOriginalContent(null)}
			controlledContent={controlledContent}
		/>

		<DesignerDialog isOpen={displayModal.state} onClose={() => onCloseHandle()} title='Text' titleIcon='a'>
			<TextDesignerDialog id={id} controlledContent={controlledContent} onCloseHandle={onCloseHandle}/>
		</DesignerDialog>
	</>;
}

interface AddTextProps {
	id: number;
	modalState: (state: boolean) => void;
}