import React from 'react';

import BlackImg from './black.jpg';
import ClearImg from './clear.jpg';
import DarkBlueImg from './darkBlue.jpg';
import GoldImg from './gold.jpg';
import GreenImg from './green.jpg';
import LightBlueImg from './lightBlue.jpg';
import MaroonImg from './maroon.jpg';
import NeonGreenImg from './neonGreen.jpg';
import NeonYellowImg from './neonYellow.jpg';
import OpaqueBlueImg from './opaqueBlue.jpg';
import OrangeImg from './orange.jpg';
import PinkImg from './pink.jpg';
import PurpleImg from './purple.jpg';
import RedImg from './red.jpg';
import SilverImg from './silver.jpg';
import TurquoiseImg from './turquoise.jpg';
import WhiteImg from './white.jpg';
import YellowImg from './yellow.jpg';

export const RealColors = {
	'black.jpg':		<img src={BlackImg} 		alt={BlackImg}		/>,
	'clear.jpg':		<img src={ClearImg} 		alt={ClearImg}		/>,
	'darkBlue.jpg':		<img src={DarkBlueImg} 		alt={DarkBlueImg}	/>,
	'gold.jpg':			<img src={GoldImg} 			alt={GoldImg}		/>,
	'green.jpg':		<img src={GreenImg} 		alt={GreenImg}		/>,
	'lightBlue.jpg':	<img src={LightBlueImg} 	alt={LightBlueImg}	/>,
	'maroon.jpg':		<img src={MaroonImg} 		alt={MaroonImg}		/>,
	'neonGreen.jpg':	<img src={NeonGreenImg} 	alt={NeonGreenImg}	/>,
	'neonYellow.jpg':	<img src={NeonYellowImg} 	alt={NeonYellowImg}	/>,
	'opaqueBlue.jpg':	<img src={OpaqueBlueImg} 	alt={OpaqueBlueImg}	/>,
	'orange.jpg':		<img src={OrangeImg} 		alt={OrangeImg}		/>,
	'pink.jpg':			<img src={PinkImg} 			alt={PinkImg}		/>,
	'purple.jpg':		<img src={PurpleImg} 		alt={PurpleImg}		/>,
	'red.jpg':			<img src={RedImg} 			alt={RedImg}		/>,
	'silver.jpg':		<img src={SilverImg} 		alt={SilverImg}		/>,
	'turquoise.jpg':	<img src={TurquoiseImg} 	alt={TurquoiseImg}	/>,
	'white.jpg':		<img src={WhiteImg} 		alt={WhiteImg}		/>,
	'yellow.jpg':		<img src={YellowImg} 		alt={YellowImg}		/>,
} as const;