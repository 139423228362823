import React from 'react';
import LogoText from '@mgp-fe/shared/assets/logo/text-logo.svg';
import {DesignerDropdown, StartOverButton, ThicknessDropdown} from '@mgp-fe/designer/modules/common/ui/components';

export default function DesignerHeader({
	allowDesignerSwitcher = true,
	allowDesignerThickness = true,
}: DesignerHeaderProps) {
	return <div
		className='grid grid-cols-2 lg:tall:grid-cols-4 gap-4 pointer-events-none z-[5]'>
		<div className='flex flex-col items-center mr-auto md:mx-0 md:mr-6 md:w-48'>
			<div className='hidden md:tall:block mr-auto h-8'>
				<LogoText className='fill-primary h-8 w-42'/>
			</div>
			<div className='block md:tall:hidden mr-auto h-6'>
				<LogoText className='fill-primary h-6 w-32'/>
			</div>
			<span
				className='mt-1 text-xs md:text-lg tracking-widest text-secondary uppercase mr-auto'>Designer</span>
		</div>
		<h2 className='hidden col-span-2 mx-auto lg:tall:flex'>Design GARD</h2>
		<StartOverButton className='px-4 sm:tall:px-10 ml-auto'/>
		{allowDesignerSwitcher ? <DesignerDropdown/> : <div className='h-10 md:h-20'/>}
		<div className='hidden lg:tall:block col-span-2'/>
		{allowDesignerThickness && <ThicknessDropdown/>}
	</div>;
}


// <div
// 	className='flex flex-row mx-auto gap-8 justify-between px-2 md:p-20 md:pt-12 top-8 right-0 w-full h-fit pointer-events-none z-[5]'>
// 	<div className='flex flex-col gap-8 md:w-68'>
// 		<div className='flex flex-col items-center mr-auto md:mx-0 md:mr-6 md:w-48'>
// 			<div className='hidden md:block mr-auto h-8'>
// 				<LogoText className='fill-primary h-8 w-42'/>
// 			</div>
// 			<div className='block md:hidden mr-auto h-6'>
// 				<LogoText className='fill-primary h-6 w-32'/>
// 			</div>
// 			<span
// 				className='mt-1 text-xs md:text-lg tracking-widest text-secondary uppercase mr-auto'>Designer</span>
// 		</div>
// 		{allowDesignerSwitcher && <DesignerDropdown/>}
// 	</div>
// 	<h2 className='hidden lg:flex'>Design GARD</h2>
// 	<div className='flex flex-col min-h-[9rem] gap-4 sm:gap-6 md:w-68'>
// 		<StartOverButton className='block px-4 sm:px-10 ml-auto'/>
// 		{allowDesignerThickness && <ThicknessDropdown/>}
// 	</div>
// </div>;

interface DesignerHeaderProps {
	allowDesignerSwitcher?: boolean;
	allowDesignerThickness?: boolean;
}