import axios, {InternalAxiosRequestConfig} from 'axios';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';
import {resolveEnvVar} from '@mgp-fe/shared/utils';

const coreApiClient = axios.create({
	baseURL: getClientEnv('coreApiUrl'),
	headers: {
		'Content-Type': 'application/ld+json',
	},
});

coreApiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	if (typeof window === 'undefined') return config;

	const accessToken = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
	if (accessToken !== null && accessToken !== 'undefined' && accessToken !== 'null') {
		try {
			config.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`;
		} catch (e) {
			//TODO: this is hotfix for admin app implementation, please reconsider
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
	}

	if (resolveEnvVar('mode') === 'development') {
		config.params = {...config.params, 'XDEBUG_SESSION_START': 'PHPSTORM'};
	}

	return config;
});

// Function to check if a string is a date in the ISO 8601 format
function isISO8601Date(value: string): boolean {
	const regex = /^\d{4}-\d{2}-\d{2}$/;
	return regex.test(value);
}

function isISO8601DateTime(value: string): boolean {
	const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([+-]\d{2}:\d{2}|Z)?$/;
	return regex.test(value);
}

// Recursive function to transform all dates
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function transformDates(obj: any): any {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	if (Array.isArray(obj)) {
		return obj.map(transformDates);
	}

	for (const key of Object.keys(obj)) {
		const value = obj[key];
		if (typeof value === 'string' && isISO8601Date(value) || isISO8601DateTime(value)) {
			obj[key] = new Date(value);
		} else if (typeof value === 'object') {
			obj[key] = transformDates(value);
		}
	}

	return obj;
}

// Axios response interceptor
coreApiClient.interceptors.response.use(
	response => {
		response.data = transformDates(response.data);
		return response;
	},
	error => {
		if (error.response.status === 401
			&& (error.response.data.message?.startsWith('Invalid credentials.')
				|| error.response.data.message?.includes('An authentication exception occurred.'))
		) {
			localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
			alert('Credentials was invalid, please sign in again.');
			window.location.href = '/';
		}

		return Promise.reject(error);
	},
);

export default coreApiClient;