import React, {useContext, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {useGetBase64FromContent} from '@mgp-fe/designer/hooks/get-base64-from-content.ts';
import {parseCanvasDataToOldFormat, parseColorsToOldData} from '@mgp-fe/designer/modules/common/utils';
import {CanvasData, ThicknessType} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {useCreateCartItemMutation} from '@mgp-fe/shared/core-api/mutations/cart/my-items.ts';
import useProductsListQuery from '@mgp-fe/shared/core-api/queries/product/list.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {DesignerDialog} from '@mgp-fe/designer/modules/common/ui/components';
import DesignerThicknessModalContent from '@mgp-fe/shared/modules/shop/components/DesignerThicknessModalContent.tsx';

export function AddToCart() {
	const {id} = useParams();
	const [query] = useSearchParams();
	const {user} = useAuth();

	const designerContext = useContext(DesignerContext);
	const displayModal = useToggle({initialState: false});

	const [thickness, setThickness] = useState<ThicknessType | undefined>(undefined);

	const addItemToCartMutation = useCreateCartItemMutation({
		onSuccess: () => parent.postMessage('close-iframe', '*'),
	});
	const productsQuery = useProductsListQuery({
		params: {
			pagination: false,
		},
	});

	const contentImages = useGetBase64FromContent(designerContext.content);

	const onSubmit = async () => {
		const colors = parseColorsToOldData(designerContext.sectionColors);

		const canvasData = parseCanvasDataToOldFormat({
			content: designerContext.content,
			images: contentImages,
		});
		
		addItemToCartMutation.mutate({
			customer: user?.['@id'],
			product: productsQuery.data?.data?.['hydra:member'].find((product) => product.productType === 'full_custom_mouthguard')?.['@id'] ?? '',
			quantity: parseInt(query.get('quantity') ?? '1'),
			canvasData: canvasData as never as CanvasData,
			colors: colors,
			texture: designerContext.screenshot.textureScreenshot as string,
			screenshot: designerContext.screenshot.sceneScreenshot as string,
			mouthguardDesign: '/mouthguard_designs/' + id ?? '',
			mouthguardThickness: thickness,

		});
	};

	return <div className='flex w-full'>
		<Button className='h-fit !mx-auto' onClick={displayModal.toggle}>Add to cart</Button>
		<DesignerDialog isOpen={displayModal.state} onClose={displayModal.toggle} title='Select thickness'>
			<div className='flex flex-col gap-8 w-[20rem] sm:w-[30rem]'>
				<DesignerThicknessModalContent onChange={value => setThickness(value)}/>
				<Button onClick={onSubmit} state={addItemToCartMutation.status}>Add to cart</Button>
			</div>
		</DesignerDialog>
	</div>;
}