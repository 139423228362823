import React from 'react';
import {AIcon, TriangleDownIcon} from '@mgp-fe/designer/assets';
import {ContentText} from '@mgp-fe/designer/state/domain';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function AthleteControlButton({onClick, onRemove, controlledContent}: AthleteControlButtonProps) {
	return <div
		onClick={onClick}
		className='flex flex-col w-20 h-20 sm:w-40 sm:h-40 relative select-none'>
		<TriangleDownIcon className='absolute h-full w-full z-[1]'/>
		{controlledContent?.label === 'Player' ? <><
			h5 className='mx-auto pt-2 sm:pt-8 text-muted text-sm sm:text-lg z-[2]'>Your text</h5>
		<AIcon className='mx-auto h-4 sm:h-8 mt-3 z-[2]'/>
		</> : <div className='flex m-auto w-2/3 h-1/2 items-center'>
			<h5 className='m-auto max-w-full text-muted z-[2] text-sm sm:text-lg truncate break-all'>{controlledContent?.label}</h5>
		</div>}
		{(controlledContent?.label !== 'Player' && onRemove) && <FontAwesomeIcon
			onClick={event => {
				event.stopPropagation();
				onRemove();
			}}
			className='ml-auto mt-auto z-[3] text-destructive-500 hover:text-destructive-800 duration-300'
			icon='trash-can'/>}
	</div>;
}

interface AthleteControlButtonProps {
	onClick: () => void;
	onRemove?: () => void;
	controlledContent: ContentText | undefined;
}