import React, {useContext, useEffect} from 'react';
import {TrashCanIcon, UploadArrowUpIcon} from '@mgp-fe/designer/assets';
import {FileUploader} from 'react-drag-drop-files';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {ContentImage} from '@mgp-fe/designer/state/domain';

export default function ImageUploader({id, controlledContent}: ImageUploaderProps) {
	const designerContext = useContext(DesignerContext);

	const hasFile = designerContext.content[designerContext.content.findIndex(c => c.id === id)].image?.file;

	useEffect(() => {
		if (!controlledContent?.file) {
			designerContext.dispatchContent({
				id,
				content: {
					type: 'image',
					rotation: 0,
					flipX: false,
					flipY: false,
					scale: 100,
				},
			});
		}
	}, []);

	return <div className='relative'>
		<FileUploader
			types={['JPG', 'JPEG', 'PNG', 'SVG', 'WEBP', 'HEIC', 'HEIF', 'HEVC']}
			handleChange={(event: File) => {
				if (event) {
					designerContext.dispatchContent({
						id,
						content: {
							file: event,
							type: 'image',
						},
					});
				}
			}} name='file'>
			<div className='flex flex-col md:w-[42.5rem] h-[14.3rem] border border-secondary-500/50 rounded-md'>
				{!hasFile ?
					<>
						<UploadArrowUpIcon className='mx-auto mt-auto'/>
						<h5 className='text-muted mx-auto mb-auto'>Drag & drop your image</h5>
					</>
					:
					<img
						alt='displayed file'
						className='z-[4] m-auto object-contain w-[95%] h-full max-h-[12rem]'
						src={controlledContent?.file ? URL.createObjectURL(controlledContent.file) : ''}/>}

			</div>
		</FileUploader>
		{hasFile && <TrashCanIcon
			className='absolute top-1 right-1 z-[6] cursor-pointer'
			onClick={event => {
				event.stopPropagation();
				designerContext.dispatchContent({
					id,
					content: {
						type: 'undefined',
					},
				});
			}}/>}
	</div>;
}

interface ImageUploaderProps {
	id: number;
	controlledContent: ContentImage | undefined;
}