'use client';

import {useCallback, useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export function useGetRecaptchaToken(action: string) {
	const {executeRecaptcha} = useGoogleReCaptcha();
	const [isReady, setReady] = useState(false);

	useEffect(() => {
		if (executeRecaptcha) {
			setReady(true);
		}
	}, [executeRecaptcha]);

	return useCallback(async (): Promise<string> => {
		if (!isReady) return 'RECAPTCHA_NOT_READY';
		if (!executeRecaptcha) return 'MISSING_EXECUTE_RECAPTCHA';

		try {
			return await executeRecaptcha(action);
		} catch (error) {
			return 'RECAPTCHA_ERROR';
		}
	}, [isReady, executeRecaptcha, action]);
}
