'use client';

import {createContext} from 'react';
import {AuthContextValue} from '@mgp-fe/shared/modules/auth/domain.ts';
import {User} from '@mgp-fe/shared/core-api/domain/user.ts';
import {AxiosResponse} from 'axios';
import {LoginMutationResult} from '@mgp-fe/shared/core-api/mutations/auth/login.ts';

export default createContext<AuthContextValue<User>>({
	login: () => Promise.resolve<AxiosResponse<LoginMutationResult>>({} as AxiosResponse<LoginMutationResult>),
	logout: () => Promise.resolve(),
	loginWithToken: () => Promise.resolve(),
	user: null,
	isUserLoading: false,
	isUserLoaded: false,
	displayLoginModal: {
		state: false,
		on() {},
		off() {},
		toggle() {},
	},
});