import {useSearchParams} from 'react-router-dom';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useEffect} from 'react';

export function useLoginWithToken() {
	const [query] = useSearchParams();
	const [, setToken] = useLocalStorage<string|null>(AUTH_LOCAL_STORAGE_KEY, null);
    
	useEffect(() => {
		if (query.get('token'))
			setToken(query.get('token') || '');

	}, []);
}