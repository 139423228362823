'use client';

import {useQuery} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function useMeQuery<T>() {
	return useQuery(
		keysResolver(endpoints.me.index),
		() => {
			if (localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) === null) return null;

			return coreApiClient.get<T | undefined | null>(endpoints.me.index);
		},
		{
			keepPreviousData: false,
			staleTime: Infinity,
			cacheTime: Infinity,
			initialData: null,
		},
	);
}