import React, {useContext} from 'react';
import {ClearDesignColorPicker, ImageDesigner, TextDesigner} from '@mgp-fe/designer/modules/common/ui/components';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';

export function ClearLayout() {
	const designerContext = useContext(DesignerContext);

	const controlledContent = designerContext.content[1];

	return <main className='container'>
		<div className='flex flex-col justify-between md:p-8 tall:gap-8'>
			<div className='flex items-center justify-between'>
				<ClearDesignColorPicker className='w-48 mx-auto h-fit'/>
			</div>
			<div className='relative mx-auto'>
				{!controlledContent.image?.file && !controlledContent.text?.label &&
					<h5 className='hidden tall:block text-center select-none'>ADD</h5>}
				<div className='flex mt-4 tall:mt-0'>
					{!controlledContent.image?.file &&
						<TextDesigner id={controlledContent.id} modalState={() => undefined}/>}
					{!controlledContent.image?.file && !controlledContent.text?.label &&
						<div
							className='absolute h-[8.2rem] lg:tall:h-[10.5rem] tall:top-6 md:tall:top-9 lg:tall:top-8 left-[8rem] md:left-[7.9rem] lg:tall:left-[10rem] border-r border-dashed border-secondary rotate-[32deg]'/>
					}
					{!controlledContent.text?.label &&
						<ImageDesigner id={controlledContent.id} modalState={() => undefined}/>}
				</div>
			</div>
		</div>
	</main>;
}