import React, {useContext} from 'react';
import {ColorPicker, Droppable} from '@mgp-fe/designer/modules/common/ui/components';
import {DndContext, DragEndEvent, PointerSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';

export default function DraggableLayout({draggableMarkup, handleDragEnd}: DraggableLayoutProps) {
	const designerContext = useContext(DesignerContext);

	const mouseSensor = useSensor(PointerSensor, {
		activationConstraint: {
			distance: 10,
		},
	});

	const touchSensor = useSensor(TouchSensor, {
		activationConstraint: {
			delay: 250,
			tolerance: 5,
		},
	});

	const sensors = useSensors(
		mouseSensor,
		touchSensor,
	);

	return <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
		<div className='flex flex-col justify-between h-full md:p-8 gap-8 touch-none'>
			<div className='grid grid-cols-3 md:tall:flex-row items-center'>
				<div className=''>
					<Droppable key={0} id={0}>
						{draggableMarkup[designerContext.content[0].id] ?? 'Drop here'}
					</Droppable>
				</div>
				<ColorPicker
					className='w-fit lg:tw-48 h-fit mx-auto hidden md:tall:flex py-3 px-6 text-lg lg:text-xl lg:py-5 lg:px-10'/>
				<div className='order-2 md:tall:order-none'>
					<Droppable key={2} id={2}>
						{draggableMarkup[designerContext.content[2].id] ?? 'Drop here'}
					</Droppable>
				</div>
				<div className='hidden md:tall:block'/>
				<div className='order-1 md:tall:order-none'>
					<Droppable key={1} id={1}>
						{draggableMarkup[designerContext.content[1].id] ?? 'Drop here'}
					</Droppable>
				</div>
			</div>
		</div>
	</DndContext>;
}

interface DraggableLayoutProps {
	draggableMarkup: React.JSX.Element[];
	handleDragEnd: (event: DragEndEvent) => void;
}