import {designerSections} from '@mgp-fe/designer/state/domain';

export const MouthguardSections = {
	left3rd: [designerSections.LEFT_3RD],
	right3rd: [designerSections.RIGHT_3RD],
	center: [designerSections.RIGHT_HALF, designerSections.LEFT_HALF],
	leftHalf: [designerSections.LEFT_HALF, designerSections.LEFT_3RD],
	rightHalf: [designerSections.RIGHT_3RD, designerSections.RIGHT_HALF],
	full: [designerSections.RIGHT_3RD, designerSections.RIGHT_HALF, designerSections.LEFT_HALF, designerSections.LEFT_3RD],

};