import React, {useContext} from 'react';
import {ColorPicker, DraggableContentTeam} from '@mgp-fe/designer/modules/common/ui/components';
import {DragEndEvent} from '@dnd-kit/core';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {ContentPositionType} from '@mgp-fe/designer/state/domain';
import DraggableLayout from '@mgp-fe/designer/modules/common/ui/layout/draggable-layout.tsx';

export function TeamLayout() {
	const designerContext = useContext(DesignerContext);


	const draggableMarkup = [
		<DraggableContentTeam key={1} permanentId={0} id={designerContext.content.findIndex(c => c.id === 0) + 1}/>,
		<DraggableContentTeam key={2} permanentId={1} id={designerContext.content.findIndex(c => c.id === 1) + 1}/>,
		<DraggableContentTeam key={3} permanentId={2} id={designerContext.content.findIndex(c => c.id === 2) + 1}/>,
	];

	function handleDragEnd(event: DragEndEvent) {
		const {active, over} = event;

		if (!active || !over) return;

		designerContext.dispatchContent({
			id: active.data.current?.permanentId,
			position: over.id as ContentPositionType,
		});
	}

	return <main className='container'>
		<ColorPicker className='w-48 mb-4 mx-auto h-fit flex md:tall:hidden z-[4] py-2 px-4 text-lg'/>
		<DraggableLayout draggableMarkup={draggableMarkup} handleDragEnd={handleDragEnd}/>
	</main>;
}