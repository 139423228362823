import React, {useContext} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {AthleteEditText} from '@mgp-fe/designer/modules/common/ui/components/athlete-text/athlete-edit-text.tsx';
// import {TriangleDownIcon} from '@mgp-fe/designer/assets';

export default function AthleteContent({id}: AthleteContentProps) {
	const designerContext = useContext(DesignerContext);
	const controlledContent = designerContext.content[designerContext.content.findIndex(c => c.id === id)];

	if (!controlledContent) return;

	return <div className='m-auto' style={{
		opacity: controlledContent.athlete ? '100%' : '50%',
		cursor: controlledContent.athlete ? 'pointer' : 'default',
	}}>
		{controlledContent.text && <AthleteEditText id={id} enabled={controlledContent.athlete}/>}
		{/*{controlledContent.image?.file && <div*/}
		{/*	className='flex flex-col w-40 h-40 relative select-none'>*/}

		{/*	<TriangleDownIcon className='absolute h-full w-full z-[1]'/>*/}

		{/*	<img*/}
		{/*		className='z-[4] m-auto max-w-[6rem]'*/}
		{/*		src={controlledContent.image?.file ? URL.createObjectURL(controlledContent.image.file) : ''}*/}
		{/*		alt='content image'*/}
		{/*	/>*/}
		{/*</div>}*/}
		{!controlledContent.text?.label && !controlledContent.image?.file && <></>}
	</div>;
}

interface AthleteContentProps {
	id: number;
}