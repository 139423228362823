import * as React from 'react';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '@mgp-fe/shared/utils';

const alertVariants = cva(
	'relative w-full  border [&>svg]:text-foreground font-medium',
	{
		variants: {
			variant: {
				default: 'bg-background text-foreground',
				success: 'bg-secondary-300 text-secondary-900 border-secondary-950 border-2 [&>h5]:text-secondary-900 [&>svg]:text-secondary-900',
				warning: 'bg-warning/50 border-warning text-warning-foreground [&>h5]:text-warning-foreground [&>svg]:text-warning-foreground',
				destructive:
					'bg-destructive/60 border-destructive text-destructive-foreground [&>svg]:text-destructive-foreground [&>h5]:text-destructive-foreground',
			},
			size: {
				default: 'my-6 p-4 rounded-lg',
				xs: 'my-1 px-2 py-1 text-xs rounded-sm',
				xl: 'my-8 p-6 rounded-lg text-3xl font-semibold',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
);

const Alert = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({className, variant, size, ...props}, ref) => (
	<div
		ref={ref}
		role='alert'
		className={cn(alertVariants({variant, size}), className)}
		{...props}
	/>
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement>
>(({className, ...props}, ref) => (
	<h5
		ref={ref}
		className={cn('mb-mini font-medium leading-none tracking-tight', className)}
		{...props}
	/>
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({className, ...props}, ref) => (
	<div
		ref={ref}
		className={cn('text-sm font-normal [&_p]:leading-relaxed', className)}
		{...props}
	/>
));
AlertDescription.displayName = 'AlertDescription';

export {Alert, AlertTitle, AlertDescription};
