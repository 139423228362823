import React, {ReactNode} from 'react';
import {cn} from '@mgp-fe/shared/utils';
import {useSearchParams} from 'react-router-dom';

export default function Container({children}: ContainerProps) {
	const [query] = useSearchParams();
	return <div className={cn(
		'relative h-full flex flex-col lg:m-auto container bg-background rounded-xl py-10',
		!query.has('modal') ? 'shadow-lg shadow-primary-500/10' : '',
	)}>
		{children}
	</div>;
}

interface ContainerProps {
	children: ReactNode;
}