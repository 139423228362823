import React from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MouthguardTextFonts} from '@mgp-fe/designer/constants/mouthguard-text-fonts.ts';
import {cn} from '@mgp-fe/shared/utils';

export default function FontPicker({onBackHandle, onPickHandle, defaultFont}: FontPickerProps) {
	return <>
		<Button
			variant='outline'
			className='w-full'
			icon={<FontAwesomeIcon icon='chevron-left'/>}
			onClick={() => onBackHandle()}
		>
            Back
		</Button>
		<h4 className='text-center py-8 text-secondary uppercase'>Fonts</h4>
		<div className='flex flex-col w-full gap-4'>
			{MouthguardTextFonts.map(tf => <h4
				key={tf.font}
				onClick={() => onPickHandle(tf.font)}
				className={cn(
					'text-center uppercase text-muted hover:text-muted/50 cursor-pointer',
					defaultFont === tf.font ? 'text-secondary' : '',
				)}
				style={{
					fontFamily: tf.font,
				}}>
				{tf.font}
			</h4>)}
		</div>
	</>;
}

interface FontPickerProps {
    defaultFont?: string;
    onBackHandle: () => void;
    onPickHandle: (font: string) => void;
}