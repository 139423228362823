import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosResponse} from 'axios';
import {ArchivableListParams, CollectionParams, HydraListResponse, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import {ProductCollectionModel, ProductType} from '@mgp-fe/shared/core-api/domain/product.ts';
import {ChannelName} from '@mgp-fe/shared/core-api/domain/channel.ts';

export default function useProductsListQuery(props: ProductsListQueryProps) {
	return useQuery<AxiosResponse<HydraListResponse<ProductCollectionModel>>>(
		keysResolver(endpoints.products.list, 'get', props.params),
		() => coreApiClient.get(endpoints.products.list, {
			params: props.params,
		}),
		{
			staleTime: Infinity,
			...props.options,
		},
	);
}

interface ProductsListQueryProps {
	params?: CollectionParams & ArchivableListParams & {
		productType?: ProductType | ProductType[];
		'channels.name'?: ChannelName | ChannelName[];
		'categories.id'?: Iri;
	};
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<ProductCollectionModel>>>;
}