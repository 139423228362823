import {CanvasTexture} from 'three';
import {CANVAS_WIDTH, LOGO_HEIGHT} from '@mgp-fe/designer/constants';
import {ContentType} from '@mgp-fe/designer/state/domain';

export function addContent({context, texture, position, content}: AddContentProps){


	if (content[0].text){
		context.save();
		context.font = `${content[0].text.size ?? 50}px ${content[0].text.font ?? 'Bebas Neue'}`;
		context.fillStyle = content[0].text.color ?? 'red';
		context.textAlign = 'center';
		context.fillText(content[0].text.label ?? '', position.x, position.y + (content[0].text.size ?? 0)/4);
		context.restore();
		texture.needsUpdate = true;
	} 

	if (content[0].image){
		const image = new Image();
		if (content[0].image.file)
			image.src = URL.createObjectURL(content[0].image.file);

		image.onload = () => {
			context.save();
			//Temporary image scale until it is added to context
			const tempScale = (content[0].image?.scale ?? 100) / 100;
			const newImageWidth = (image.width * LOGO_HEIGHT) / image.height * tempScale;
			const newImageHeight = LOGO_HEIGHT * tempScale;
			const imageCenterX = (position.x - (newImageWidth) / 2);
			const imageCenterY = (position.y - newImageHeight / 2);
			if (content[0].image?.flipX) {
				context.translate(position.x + CANVAS_WIDTH / 2, 0);
				context.scale(-1, 1);
				context.translate(-position.x + CANVAS_WIDTH / 2, 0);
			}
			if (content[0].image?.flipY){
				context.translate(0, position.y * 2);
				context.scale(1, -1);
			}

			context.translate(imageCenterX + newImageWidth / 2, imageCenterY + newImageHeight / 2);
			context.rotate((content[0].image?.rotation ?? 0) * Math.PI / 180);
			context.translate(-(newImageWidth * 0.5), -(newImageHeight * 0.5));

			context.drawImage(image, 0, 0, newImageWidth, newImageHeight);
			context.restore();
			texture.needsUpdate = true;
		};
	}

}

type AddContentProps = {
    content: ContentType;
    context:  CanvasRenderingContext2D;
    texture: CanvasTexture;
    position: {
        x: number;
        y: number;
    }
}