'use client';

import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import {cn} from '@mgp-fe/shared/utils';
import {cva, VariantProps} from 'class-variance-authority';

const tabsTriggerVariants = cva(
	'',
	{
		variants: {
			variant: {
				default: 'grow inline-flex items-center justify-center whitespace-nowrap px-medium py-small text-[22px] leading-none text-sm text-muted font-semibold ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 border-2 border-primary last-of-type:rounded-r-lg first-of-type:rounded-l-lg disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-primary data-[state=active]:text-background data-[state=active]:shadow-sm',
				underline: 'text-medium font-medium rounded-none whitespace-nowrap normal-case mr-small md:mr-large py-small text-[#8A8A8A] border-[#8A8A8A] border-b-[1px] outline-none focus:outline-none data-[state=active]:border-secondary data-[state=active]:text-secondary data-[state=active]:border-b-[1px]',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	} as const,
);

export interface TabsTriggerProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>, VariantProps<typeof tabsTriggerVariants> {
}

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({className, ...props}, ref) => (
	<TabsPrimitive.List
		ref={ref}
		className={cn(
			'inline-flex items-stretch justify-center rounded-lg overflow-hidden bg-background w-full min-h-[60px]§',
			className,
		)}
		{...props}
	/>
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	TabsTriggerProps
>(({className, variant, ...props}, ref) => (
	<TabsPrimitive.Trigger
		ref={ref}
		className={cn((tabsTriggerVariants({variant})), className)}
		{...props}
	/>
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({className, ...props}, ref) => (
	<TabsPrimitive.Content
		ref={ref}
		className={cn(
			'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
			className,
		)}
		{...props}
	/>
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export {Tabs, TabsList, TabsTrigger, TabsContent};
