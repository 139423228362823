import React, {useContext, useEffect, useState} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {MouthguardColors, MouthguardSections} from '@mgp-fe/designer/constants';
import {cn} from '@mgp-fe/shared/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	DesignerSection,
	MouthguardSectionType,
	mouthguardSectionTypes,
	SectionColorsType,
} from '@mgp-fe/designer/state/domain';
import {DesignerDialog} from '@mgp-fe/designer/modules/common/ui/components/designer-dialog.tsx';
import PositionCard from '@mgp-fe/designer/modules/common/ui/components/color-picker/position-card.tsx';
import {ColorModeSelect} from '@mgp-fe/designer/modules/common/ui/components/color-picker/color-mode-select.tsx';
import {RealColors} from '@mgp-fe/designer/assets/real-colors';
import {DesignPreview} from '@mgp-fe/designer/modules/common';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@mgp-fe/shared/ui/tooltip.tsx';

export function ColorPicker({className}: ColorPickerProps) {
	const designerContext = useContext(DesignerContext);
	const displayModal = useToggle({initialState: false});
	const [sectionTypes, setSectionTypes] = useState<MouthguardSectionType>('FULL_COLOR');
	const [originalSectionTypes, setOriginalSectionTypes] = useState<MouthguardSectionType>('FULL_COLOR');
	const [position, setPosition] = useState<DesignerSection[] | undefined>();
	const [color, setColor] = useState<string | undefined>('#4a8943');
	const [sectionColors, setSectionColors] = useState<SectionColorsType>();

	useEffect(() => {
		if (displayModal.state) {
			setSectionColors(designerContext.sectionColors);
			setOriginalSectionTypes(sectionTypes);
		}
		if (!displayModal.state) {
			setSectionColors(undefined);
		}
	}, [displayModal.state]);

	useEffect(() => {
		setSectionTypes(compareColorSections());
	}, [sectionColors]);

	const pickColorHandler = ({pos, col}: { pos?: DesignerSection[], col?: string }) => {
		if (pos && col) {
			pos.forEach(p => designerContext.dispatchSectionColors({
				section: p,
				color: col,
			}));
		}
	};

	const compareColorSections = () => {
		const l3 = sectionColors?.left3rd.color;
		const lh = sectionColors?.leftHalf.color;
		const rh = sectionColors?.rightHalf.color;
		const r3 = sectionColors?.right3rd.color;

		if ([l3, lh, rh, r3].filter(c => c === lh).length === 4)
			return mouthguardSectionTypes.full;

		if (lh !== rh && lh === l3)
			return mouthguardSectionTypes.twoColors;

		if (lh === rh && lh !== l3)
			return mouthguardSectionTypes.threeColors;

		return mouthguardSectionTypes.full;
	};

	const handleClose = () => {
		(['left3rd', 'leftHalf', 'rightHalf', 'right3rd'] as DesignerSection[])
			.map(s => designerContext.dispatchSectionColors({
				section: s,
				color: sectionColors![s].color!,
			}));

		setSectionTypes(originalSectionTypes);

		displayModal.off();
	};


	const handleSave = () => {
		setOriginalSectionTypes(sectionTypes);
		displayModal.off();
	};

	const handleSectionSwitch = (section: MouthguardSectionType) => {

		if (section === sectionTypes) return;

		(['left3rd', 'leftHalf', 'rightHalf', 'right3rd'] as DesignerSection[])
			.map(s => designerContext.dispatchSectionColors({
				section: s,
				color: '#4a8943',
			}));

		if (section === 'FULL_COLOR') {
			setPosition(MouthguardSections.full);
			setColor('#4a8943');
		}
		if (section === 'TWO_COLORS') {
			setPosition(MouthguardSections.leftHalf);
			setColor('#4a8943');
		}
		if (section === 'THREE_COLORS') {
			setPosition(MouthguardSections.left3rd);
			setColor('#4a8943');
		}

		setSectionTypes(section);
	};

	const colors = designerContext.sectionColors;

	return <>
		<Button
			onClick={() => displayModal.on()}
			className={className}
			variant={'outline'}
			icon={<FontAwesomeIcon icon='palette'/>}
		>Colors</Button>

		<DesignerDialog isOpen={displayModal.state} onClose={handleClose} title='Colors' titleIcon='palette'>
			<div className='hidden md:flex'>
				<TooltipProvider>
					<Tooltip delayDuration={0}>
						<TooltipTrigger className='ml-auto'>
							<FontAwesomeIcon icon='info-circle' fontVariant='far'/>
						</TooltipTrigger>
						<TooltipContent className='border-muted bg-background rounded-lg w-64 px-4 py-4'>
							<p className='text-muted'>
								Color represented in designer may be different than the finished product.
							</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
			<p className='md:hidden text-muted/50 text-xs mb-2'>
				Color represented in designer may be different than the finished product.
			</p>
			<div className='md:w-[20rem] h-24 sm:h-44 mx-auto'>
				<DesignPreview contentPosition={3}/>
			</div>
			<ColorModeSelect
				selectedSectionType={sectionTypes}
				onClick={handleSectionSwitch}/>

			<div className='flex flex-col md:flex-row mx-auto'>
				{{
					'FULL_COLOR': <div className='w-full my-4 mx-auto'>
						<PositionCard
							label='Full color'
							cardPositions={MouthguardSections.full}
							color={colors.leftHalf.color}
							position={position}
							setPosition={value => {
								setPosition(value);
								setColor(colors.leftHalf.color);
							}}
						/>
					</div>,
					'TWO_COLORS': <div className='w-full grid grid-cols-2 gap-1 my-4 mx-auto'>
						<PositionCard
							label='Left half'
							cardPositions={MouthguardSections.leftHalf}
							color={colors.leftHalf.color}
							position={position}
							setPosition={value => {
								setPosition(value);
								setColor(colors.leftHalf.color);
							}}
						/>
						<PositionCard
							label='Right half'
							cardPositions={MouthguardSections.rightHalf}
							color={colors.rightHalf.color}
							position={position}
							setPosition={value => {
								setPosition(value);
								setColor(colors.rightHalf.color);
							}}
						/></div>,
					'THREE_COLORS': <div className='w-full grid grid-cols-3 gap-1 my-4 mx-auto'>
						<PositionCard
							label='Left 3rd'
							cardPositions={MouthguardSections.left3rd}
							color={colors.left3rd.color}
							position={position}
							setPosition={value => {
								setPosition(value);
								setColor(colors.left3rd.color);
							}}
						/>
						<PositionCard
							label='Center'
							cardPositions={MouthguardSections.center}
							color={colors.leftHalf.color === colors.rightHalf.color ? colors.rightHalf.color : undefined}
							position={position}
							setPosition={value => {
								setPosition(value);
								setColor(colors.rightHalf.color);
							}}
						/>
						<PositionCard
							label='Right 3rd'
							cardPositions={MouthguardSections.right3rd}
							color={colors.right3rd.color}
							position={position}
							setPosition={value => {
								setPosition(value);
								setColor(colors.right3rd.color);
							}}
						/>
					</div>,
				}[sectionTypes]}
			</div>

			<div className='flex'>
				<div className='flex flex-col'>
					<div className='flex flex-wrap max-w-[25rem] gap-3 sm:gap-7'>
						{MouthguardColors.map(c => <div
							key={c.color}
							className={cn(
								'h-9 w-9 flex bg-transparent rounded-full cursor-pointer hover:scale-110 hover:border-2 hover:border-secondary duration-300',
								color === c.color ? 'border-2 border-secondary scale-105 hover:scale-105 cursor-default' : '',
							)}
						>
							<div
								onClick={() => {
									setColor(c.color);
									pickColorHandler({
										col: c.color,
										pos: sectionTypes === 'FULL_COLOR' ? MouthguardSections.full : position,
									});
								}}
								className={cn(
									'h-8 w-8 m-auto rounded-full duration-300',
									color === c.color ? 'h-6 w-6' : '',
								)}
								style={{backgroundColor: c.color}}
							/>
						</div>)}
					</div>
				</div>

				<div className='m-auto w-fit'>
					<p className='w-24 sm:w-28 text-md text-muted text-center'>Selected</p>
					<div className='w-24 sm:w-28 aspect-1 bg-muted border-2 border-primary rounded-xl overflow-hidden'>
						{RealColors[MouthguardColors.find(c => c.color === color)
							?.img as keyof typeof RealColors]}
					</div>
					<p className='w-24 sm:w-28 text-md text-muted text-center'>{MouthguardColors.find(c => c.color === color)?.name}</p>
				</div>
			</div>

			<div className='flex mt-4 sm:mt-16 gap-2'>
				<Button className='block sm:hidden mx-auto w-20 !p-0 aspect-1' variant='destructive'
					onClick={handleClose}>
					<FontAwesomeIcon icon='xmark'/>
				</Button>
				<Button className='sm:max-w-[16rem] mx-auto w-full' onClick={handleSave}>Save</Button>
			</div>
		</DesignerDialog>
	</>;
}

interface ColorPickerProps {
	className?: string;
}