import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {AIcon, TriangleMobile, UploadImageIcon} from '@mgp-fe/designer/assets';
import {DesignerDialog} from '@mgp-fe/designer/modules/common/ui/components/designer-dialog.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {TextDesignerDialog} from '@mgp-fe/designer/modules/common/ui/components/text-designer/text-designer-dialog.tsx';
import ImageUploader from '@mgp-fe/designer/modules/common/ui/components/image-designer/image-uploader.tsx';
import React, {Dispatch, useContext, useState} from 'react';
import {ContentType} from '@mgp-fe/designer/state/domain';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ImageControls from '@mgp-fe/designer/modules/common/ui/components/image-designer/image-controls.tsx';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';

export function AddModal(props: AddModalProps) {
	const designerContext = useContext(DesignerContext);
	const displayChooseModal = useToggle({initialState: false});
	const displayImageAddModal = useToggle({initialState: false});
	const displayImageEditModal = useToggle({initialState: false});
	const displayTextModal = useToggle({initialState: false});
	const [originalContent, setOriginalContent] = useState<ContentType[number] | undefined | null>(null);


	function removeContent() {
		designerContext.dispatchContent({
			id: props.permanentId,
			content: {
				type: 'undefined',
			},
		});
		setOriginalContent(null);
	}

	function onCloseChooseModalHandle() {
		displayChooseModal.off();
		props.setDisabled(false);
	}

	function onCloseTextModalHandle(isSave?: boolean) {
		console.log(isSave);
		if (isSave) {
			setOriginalContent(props.controlledContent);
		} else if (originalContent) {
			designerContext.dispatchContent({
				id: props.permanentId,
				content: originalContent.text,
			});
		} else {
			designerContext.dispatchContent({
				id: props.permanentId,
				content: {
					type: 'undefined',
				},
			});
		}
		displayTextModal.off();
		props.setDisabled(false);
	}

	function onCloseImageModalHandle(isSave?: boolean) {
		if (isSave) {
			setOriginalContent(props.controlledContent);
		} else if (originalContent) {
			designerContext.dispatchContent({
				id: props.permanentId,
				content: originalContent.image,
			});
		} else {
			designerContext.dispatchContent({
				id: props.permanentId,
				content: {
					type: 'undefined',
				},
			});
		}
		displayImageAddModal.off();
		displayImageEditModal.off();
		props.setDisabled(false);
	}


	function AddContentButtons() {
		return <>
			<Button
				onClick={() => {
					displayTextModal.on();
					displayChooseModal.off();
					props.setDisabled(false);
				}}
				className='flex text-muted text-[14px] h-14'
				size='default'
				variant='secondary'
				icon={<AIcon className='mx-auto'/>}
			>
				TEXT
			</Button>
			OR
			<Button
				onClick={() => {
					displayImageAddModal.on();
					displayChooseModal.off();
					props.setDisabled(false);
				}}
				className='flex text-muted text-[14px] h-14'
				size='default'
				disabled={props.controlledContent.athlete}
				variant='secondary'
				icon={<UploadImageIcon className='m-auto'/>}>
				IMAGE
			</Button>
		</>;
	}

	function EditTextButtons() {
		return <>
			<Button
				onClick={() => {
					displayTextModal.on();
					displayChooseModal.off();
					props.setDisabled(false);
				}}
				className='flex text-muted text-[14px] h-14'
				size='default'
				variant='secondary'
				icon={<AIcon className='mx-auto'/>}
			>
				TEXT
			</Button>
			<Button
				onClick={removeContent}
				className='flex text-muted text-[14px] h-14'
				size='default'
				variant='destructive'
				icon={<FontAwesomeIcon icon='trash-can'/>}
			>
				Remove
			</Button>
		</>;
	}

	function EditImageButtons() {
		return <>
			<Button
				onClick={() => {
					displayImageEditModal.on();
					displayChooseModal.off();
					props.setDisabled(false);
				}}
				className='flex text-muted text-[14px] h-14'
				size='default'
				variant='secondary'
				icon={<UploadImageIcon className='m-auto'/>}
			>
				Image
			</Button>
			<Button
				onClick={removeContent}
				className='flex text-muted text-[14px] h-14'
				size='default'
				variant='destructive'
				icon={<FontAwesomeIcon icon='trash-can'/>}
			>
				Remove
			</Button>
		</>;
	}

	return <>
		<div
			onClick={() => {
				displayChooseModal.on();
				props.setDisabled(true);
			}}
			className='relative flex mx-auto w-fit'>
			<TriangleMobile className='w-24 h-24 sm:w-32 sm:h-32' style={{rotate: props.id === 2 ? '180deg' : ''}}/>
			{props.id === 2 ?
				<div
					className='absolute inset-0 flex w-1/2 h-1/2 flex-col mt-10 m-auto z-[2] cursor-pointer'>
					{!props.controlledContent?.image?.file && !props.controlledContent?.text?.label ?
						<>
							<h5 className='mx-auto pt-2 text-muted z-[2] text-[0.8rem] sm:text-[1rem]'>+
								ADD</h5>
						</>
						: props.controlledContent.image?.file ?
							<div className='w-full h-full z-[4] m-auto'>
								<img
									className='object-cover h-full'
									src={props.controlledContent?.image?.file ? URL.createObjectURL(props.controlledContent?.image?.file) : ''}
									alt='content image'
								/>
							</div> :
							<div className='flex flex-col mx-auto w-full h-full items-center'>
								<p className='mx-auto max-w-full text-muted z-[2] truncate break-all text-sm sm:text-lg'>
									{props.controlledContent?.text?.label}
								</p>
								<div
									className='h-4 w-4 z-[2] mt-2 rounded-full'
									style={{backgroundColor: props.controlledContent?.text?.color}}/>
							</div>}
				</div> :
				<div className='absolute inset-0 flex w-1/2 h-1/2 flex-col mt-4 m-auto z-[2] cursor-pointer'>
					{!props.controlledContent?.image?.file && !props.controlledContent?.text?.label ?
						<>
							<h5 className='mx-auto pt-2 text-muted z-[2] text-[0.8rem] sm:text-[1rem]'>+ ADD</h5>
						</>
						:
						props.controlledContent.image?.file ?
							<div className='w-full h-full z-[4] m-auto'>
								<img
									className='object-cover h-full'
									src={props.controlledContent?.image?.file ? URL.createObjectURL(props.controlledContent?.image?.file) : ''}
									alt='content image'
								/>
							</div> :
							<div className='flex flex-col mx-auto w-full h-full items-center'>
								<p className='mx-auto max-w-full text-muted z-[2] truncate break-all text-sm sm:text-lg'>
									{props.controlledContent?.text?.label}
								</p>
								<div
									className='h-4 w-4 z-[2] mt-2 rounded-full'
									style={{backgroundColor: props.controlledContent?.text?.color}}/>
							</div>}
				</div>
			}
		</div>

		<DesignerDialog isOpen={displayChooseModal.state} onClose={onCloseChooseModalHandle}
			title={!props.controlledContent.image?.file && !props.controlledContent.text?.label ? 'Add' : 'Edit'}>
			<div className='flex w-full justify-between mx-auto items-center gap-2 my-16'>
				{!props.controlledContent.image?.file && !props.controlledContent.text?.label && <AddContentButtons/>}
				{!props.controlledContent.image?.file && props.controlledContent.text?.label && <EditTextButtons/>}
				{props.controlledContent.image?.file && !props.controlledContent.text?.label && <EditImageButtons/>}
			</div>
		</DesignerDialog>
		<DesignerDialog isOpen={displayTextModal.state} onClose={() => onCloseTextModalHandle()} title='Text'
			titleIcon='a'>
			<TextDesignerDialog
				id={props.permanentId}
				controlledContent={props.controlledContent.text}
				onCloseHandle={onCloseTextModalHandle}/>
		</DesignerDialog>

		<DesignerDialog isOpen={displayImageAddModal.state} onClose={onCloseImageModalHandle} title='Image'
			titleIcon='image'>
			<ImageUploader
				id={props.permanentId}
				controlledContent={props.controlledContent.image}/>
			<div className='flex mt-8 gap-2'>
				<Button className='block sm:hidden mx-auto w-20 !p-0 aspect-1' variant='destructive'
					onClick={() => onCloseImageModalHandle()}>
					<FontAwesomeIcon icon='xmark'/>
				</Button>
				<Button className='w-full mx-auto' onClick={() => onCloseImageModalHandle(true)}>
					Save
				</Button>
			</div>
		</DesignerDialog>

		<DesignerDialog
			isOpen={displayImageEditModal.state}
			onClose={onCloseImageModalHandle}
			title='Edit Image' titleIcon='image'>
			<p className='sm:hidden text-muted/50 text-xs'>
				Images & logos might look different on real product than in designer.
			</p>
			<ImageControls id={props.permanentId} controlledContent={props.controlledContent.image}/>
			<div className='flex mt-8 gap-2'>
				<Button className='block sm:hidden mx-auto w-20 !p-0 aspect-1' variant='destructive'
					onClick={() => onCloseImageModalHandle()}>
					<FontAwesomeIcon icon='xmark'/>
				</Button>
				<Button className='w-full mx-auto' onClick={() => onCloseImageModalHandle(true)}>
					Save
				</Button>
			</div>
		</DesignerDialog>
	</>;
}

interface AddModalProps {
	id: number;
	permanentId: number;
	setDisabled: Dispatch<React.SetStateAction<boolean>>;
	controlledContent: ContentType[number];
}