import React from 'react';
import {cn} from '@mgp-fe/shared/utils';
import {MouthguardSectionType, mouthguardSectionTypes} from '@mgp-fe/designer/state/domain';

export function ColorModeSelect({onClick, selectedSectionType}: ColorModeSelectProps) {

	const isFull = selectedSectionType === mouthguardSectionTypes.full;
	const isTwo = selectedSectionType === mouthguardSectionTypes.twoColors;
	const isThree = selectedSectionType === mouthguardSectionTypes.threeColors;

	return <div className={'grid grid-cols-3 justify-between gap-1 mx-auto items-center'}>
		<button
			onClick={() => onClick(mouthguardSectionTypes.full)}
			className={cn(
				'bg-secondary py-3 sm:py-6 rounded-sm opacity-50 text-muted hover:opacity-100 duration-300 text-sm sm:text-[18px] md:text-[22px]',
				isFull ? 'opacity-100' : '',
			)}
		>
			Full
		</button>
		<button
			onClick={() => onClick(mouthguardSectionTypes.twoColors)}
			className={cn(
				'bg-secondary py-3 sm:py-6 rounded-sm opacity-50 text-muted hover:opacity-100 duration-300 text-sm sm:text-[18px] md:text-[22px]',
				isTwo ? 'opacity-100' : '',
			)}
		>
			2 Colors
		</button>
		<button
			onClick={() => onClick(mouthguardSectionTypes.threeColors)}
			className={cn(
				'bg-secondary py-3 sm:py-6 rounded-sm opacity-50 text-muted hover:opacity-100 duration-300 text-sm sm:text-[18px] md:text-[22px]',
				isThree ? 'opacity-100' : '',
			)}
		>
			3 Colors
		</button>
	</div>;
}

interface ColorModeSelectProps {
	onClick: (position: MouthguardSectionType) => void;
	selectedSectionType: MouthguardSectionType;
}