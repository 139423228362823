import React from 'react';
import routes from '@mgp-fe/designer/router/routes.ts';
import {AthleteDesignerPage, AthleteTeamDesignerPage, TeamDesignerPage} from '@mgp-fe/designer/modules';
import {createBrowserRouter} from 'react-router-dom';
import DesignerProvider from '@mgp-fe/designer/state/designer-provider.tsx';
import {AffiliateDesignerPage} from '@mgp-fe/designer/modules/affiliate';
import {AthleteAffiliateDesignerPage} from '@mgp-fe/designer/modules/athlete-affiliate';
import {ClearDesignerPage} from '@mgp-fe/designer/modules/clear';


const router = createBrowserRouter([
	{
		path: routes.index,
		element: <DesignerProvider><AthleteDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.athleteDesigner.index,
		element: <DesignerProvider><AthleteDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.athleteDesigner.detail,
		element: <DesignerProvider><AthleteDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.teamDesigner.index,
		element: <DesignerProvider><TeamDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.teamDesigner.detail,
		element: <DesignerProvider><TeamDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.athleteTeamDesigner.index,
		element: <DesignerProvider><AthleteTeamDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.athleteTeamDesigner.detail,
		element: <DesignerProvider><AthleteTeamDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.affiliateDesigner.index,
		element: <DesignerProvider><AffiliateDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.affiliateDesigner.detail,
		element: <DesignerProvider><AffiliateDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.athleteAffiliateDesigner.index,
		element: <DesignerProvider><AthleteAffiliateDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.athleteAffiliateDesigner.detail,
		element: <DesignerProvider><AthleteAffiliateDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.clearDesigner.index,
		element: <DesignerProvider><ClearDesignerPage/></DesignerProvider>,
	},
	{
		path: routes.clearDesigner.detail,
		element: <DesignerProvider><ClearDesignerPage/></DesignerProvider>,
	},

]);

export default router;

