import {MouthguardColors} from '@mgp-fe/designer/constants';
import {SectionColorsType} from '@mgp-fe/designer/state/domain';

const parseColorToOldFormat = (color: string) => {
	return MouthguardColors.find(c => c.color === color);
};

export const parseColorsToOldData = (colors: SectionColorsType) => ({
	left3rd: parseColorToOldFormat(colors.right3rd.color),
	leftHalf: parseColorToOldFormat(colors.rightHalf.color),
	rightHalf: parseColorToOldFormat(colors.leftHalf.color),
	right3rd: parseColorToOldFormat(colors.left3rd.color),
});