import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '@mgp-fe/shared/utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Root
		ref={ref}
		className={cn(
			'relative flex w-full touch-none select-none items-center',
			className,
		)} 
		{...props}
	>
		<SliderPrimitive.Track className={cn(
			'relative h-2 w-full grow overflow-hidden rounded-full bg-muted/90',
			props.orientation === 'vertical' ? 'h-full w-5 grow-0' : '',
		)}>
			<SliderPrimitive.Range className={cn(
				'absolute h-full bg-secondary',
				props.orientation === 'vertical' ? 'w-full' : '',
			)} />
		</SliderPrimitive.Track>
		<SliderPrimitive.Thumb className='block h-5 w-5 rounded-full border-2 border-secondary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 cursor-grab active:cursor-grabbing' />
	</SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
