import * as React from 'react';

import {cn} from '@mgp-fe/shared/utils';
import {cva, VariantProps} from 'class-variance-authority';

const cardVariants = cva(
	'bg-card text-card-foreground border border-secondary-500/20 rounded-lg shadow',
	{
		variants: {
			variant: {
				default: 'bg-background text-foreground',
				transparent: 'bg-transparent text-foreground',
				foreground: 'bg-foreground text-background',
				light: 'bg-light bg-opacity-80 text-background',
				primary: 'bg-primary text-background',
				secondary: 'bg-secondary-950/80 text-secondary-100 backdrop-blur-xl',
				ghost: 'bg-transparent border-0 shadow-transparent rounded-none',
			},
			spacing: {
				default: 'p-6',
				none: 'p-0',
				sm: 'p-4',
				md: 'p-6',
				lg: 'p-8',
			},
		},
		defaultVariants: {
			variant: 'default',
			spacing: 'none',
		},
	},
);

export type CardProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>;

const Card = React.forwardRef<
	HTMLDivElement,
	CardProps
>(({className, variant, spacing, ...props}, ref) => (
	<div
		ref={ref}
		className={cn(
			cardVariants({variant, spacing}),
			className,
		)}
		{...props}
	/>
));
Card.displayName = 'Card';

const CardHeader = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({className, ...props}, ref) => (
	<div
		ref={ref}
		className={cn('flex flex-col space-y-1.5 p-6', className)}
		{...props}
	/>
));
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement>
>(({className, ...props}, ref) => (
	<h3
		ref={ref}
		className={cn(
			'text-2xl font-semibold leading-none tracking-tight',
			className,
		)}
		{...props}
	/>
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({className, ...props}, ref) => (
	<p
		ref={ref}
		className={cn('px-6 pt-0 pb-3 text-sm italic', className)}
		{...props}
	/>
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({className, ...props}, ref) => (
	<div ref={ref} className={cn('p-6 pt-0', className)} {...props} />
));
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({className, ...props}, ref) => (
	<div
		ref={ref}
		className={cn('flex items-center p-6 pt-0', className)}
		{...props}
	/>
));
CardFooter.displayName = 'CardFooter';

export {Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent, cardVariants};
