import React, {useContext, useState} from 'react';
import {useDraggable} from '@dnd-kit/core';
import {AddModal, ImageDesigner, TextDesigner} from '@mgp-fe/designer/modules/common/ui/components';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';

export function DraggableContentAdd(props: DraggableProps) {
	const [disabled, setDisabled] = useState(false);
	const designerContext = useContext(DesignerContext);
	const {attributes, listeners, setNodeRef, transform} = useDraggable({
		id: props.id,
		data: {
			permanentId: props.permanentId,
		},
		disabled: disabled,
	});

	const style = transform ? {
		transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
	} : undefined;

	const controlledContent = designerContext.content[designerContext.content.findIndex(c => c.id === props.permanentId)];

	return <div ref={setNodeRef} style={style} {...listeners} {...attributes} className='relative m-auto'>
		<div className='block md:hidden'>
			<AddModal
				id={props.id}
				permanentId={props.permanentId}
				setDisabled={setDisabled}
				controlledContent={controlledContent}/>
		</div>
		<div className='hidden md:block'>
			{controlledContent.image?.file || controlledContent.text?.label ?
				<h5 className='hidden tall:block text-center select-none'>Swap it</h5>
				:
				<h5 className='hidden tall:block text-center select-none'>ADD</h5>
			}
			<div className='flex'>
				{!controlledContent.image?.file &&
					<TextDesigner id={props.permanentId} modalState={state => setDisabled(state)}/>}
				{!controlledContent.image?.file && !controlledContent.text?.label &&
					<div
						className='absolute h-[8.2rem] lg:tall:h-[10.5rem] tall:top-6 md:tall:top-9 lg:tall:top-8 left-[8rem] md:left-[7.9rem] lg:tall:left-[10rem] border-r border-dashed border-secondary rotate-[32deg]'/>
				}
				{!controlledContent.text?.label &&
					<ImageDesigner id={props.permanentId} modalState={state => setDisabled(state)}/>}
			</div>
		</div>
	</div>;
}

interface DraggableProps {
	id: number;
	permanentId: number;
}