import {designerSections, SectionColorReducerActions, SectionColorsType} from '@mgp-fe/designer/state/domain';

export function sectionColorsReducer(state: SectionColorsType, action: SectionColorReducerActions) {
	const {section, color} = action;
	switch (section){
	case designerSections.LEFT_3RD:
		return {
			...state,
			left3rd: {
				...state.left3rd,
				color: color,
			},
		};
	case designerSections.LEFT_HALF:
		return {
			...state,
			leftHalf: {
				...state.leftHalf,
				color: color,
			},
		};
	case designerSections.RIGHT_HALF:
		return {
			...state,
			rightHalf: {
				...state.rightHalf,
				color: color,
			},
		};
	case designerSections.RIGHT_3RD:
		return {
			...state,
			right3rd: {
				...state.right3rd,
				color: color,
			},
		};
	default:
		return state;
	}
}