import React, {ReactNode} from 'react';
import {useDroppable} from '@dnd-kit/core';

export function Droppable(props: DroppableProps) {
	const {isOver, setNodeRef} = useDroppable({
		id: props.id,
	});
	const style = {
		backgroundColor: isOver ? 'rgba(79, 158, 46, 0.1)' : undefined,
		radius: '20%',
	};

	return (
		<div ref={setNodeRef} style={style} className='flex w-full h-fit'>
			{props.children}
		</div>
	);
}

interface DroppableProps {
	id: number;
    children: ReactNode;
}