import {ContentType} from '@mgp-fe/designer/state/domain';

export const parseCanvasDataToOldFormat = ({content, images}: ParseCanvasDataToOldFormatProps) => ({
	canvasParameters: {
		height: 100,
		width: 600,
	},
	data: content.map((c, index) => ({
		athleteContent: c.athlete,
		fill: c.text?.color ?? 'rgb(0,0,0)',
		fontFamily: c.text?.font,
		fontSize: Math.floor((c.text?.size ?? 1) / 3),
		position: positionResolver(index),
		rotation: c.image?.rotation,
		flipX: c.image?.flipX,
		flipY: c.image?.flipY,
		text: c.text?.label,
		scale: c.image?.scale,
		type: c.text?.label ? 'i-text' : c.image?.file ? 'image' : undefined,
		url: c.image?.file ? images[index] : undefined,
	})),
});


const positionResolver = (index: number) => {
	return {
		0: 'left',
		1: 'center',
		2: 'right',
	}[index];
};
interface ParseCanvasDataToOldFormatProps {
    content: ContentType;
    images: string[] | ArrayBuffer[] | null[];
}
