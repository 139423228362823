export default function getClientEnv(envVar: EnvVar): string {
	if (typeof process !== 'undefined') {
		// Next.js environment needs to be hardcoded, otherwise react app will fail
		return {
			webAppUrl: process?.env.NEXT_PUBLIC_SITE_URL || '',
			coreApiUrl: process?.env.NEXT_PUBLIC_CORE_API_URL || '',
			cmsUrl: process?.env.NEXT_PUBLIC_CMS_URL || '',
			cmsGraphqlUrl: process?.env.NEXT_PUBLIC_CMS_GRAPHQL_URL || '',
			customerAppUrl: process?.env.NEXT_PUBLIC_APP_CUSTOMER || '',
			partnerAppUrl: process?.env.NEXT_PUBLIC_APP_PARTNER || '',
			designerAppUrl: process?.env.NEXT_PUBLIC_APP_DESIGNER || '',
			affiliateAppUrl: process?.env.NEXT_PUBLIC_APP_AFFILIATE || '',
			stripePublicKey: process?.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || '',
			googleMapsApiKey: process?.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
			recaptchaKey: process?.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY || '',
			mode: process?.env.NODE_ENV || '',
			appName: process?.env.NEXT_PUBLIC_APP_NAME || '',
			oauthApple: process?.env.NEXT_PUBLIC_OAUTH_APPLE || '',
			oauthGoogle: process?.env.NEXT_PUBLIC_OAUTH_GOOGLE || '',
		}[envVar] || process.env[EnvVarReactMap[envVar]] || '';
	} else if (import.meta && import.meta.env) {
		// Vite environment
		return import.meta.env[EnvVarReactMap[envVar]] || '';
	}
	return ''; // Default fallback
}

export type EnvVar = 'coreApiUrl' | 'cmsUrl' |  'cmsGraphqlUrl' | 'customerAppUrl' | 'partnerAppUrl' | 'designerAppUrl' | 'affiliateAppUrl' | 'webAppUrl' | 'stripePublicKey' | 'googleMapsApiKey' | 'recaptchaKey' | 'mode' | 'appName' | 'oauthApple' | 'oauthGoogle';

const EnvVarReactMap: Record<EnvVar, string> = {
	webAppUrl: 'VITE_APP_PUBLIC_SITE_URL',
	coreApiUrl: 'VITE_APP_CORE_API_URL',
	cmsUrl: 'VITE_APP_CMS_URL',
	cmsGraphqlUrl: 'VITE_APP_CMS_GRAPHQL_URL',
	customerAppUrl: 'VITE_APP_FE_CUSTOMER_URL',
	partnerAppUrl: 'VITE_APP_FE_PARTNER_URL',
	designerAppUrl: 'VITE_APP_FE_DESIGNER_URL',
	affiliateAppUrl: 'NEXT_PUBLIC_APP_AFFILIATE',
	stripePublicKey: 'VITE_STRIPE_PUBLIC_KEY',
	googleMapsApiKey: 'VITE_MAPS_API_KEY',
	recaptchaKey: 'VITE_GOOGLE_RECAPTCHA_KEY',
	oauthApple: 'VITE_APP_OAUTH_APPLE',
	oauthGoogle: 'VITE_APP_OAUTH_GOOGLE',
	mode: 'MODE',
	appName: 'VITE_APP_NAME',
};