'use client';

import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {AUTH_LOCAL_STORAGE_KEY, HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {z} from 'zod';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {passwordSchema} from '@mgp-fe/shared/core-api/schemas/user.ts';
import {phoneNumberSchema} from '@mgp-fe/shared/core-api/schemas/phone-number.ts';
import {useGetRecaptchaToken} from '@mgp-fe/shared/hooks/useGetRecaptchaToken.ts';
import {UpdateConsentSettingsMutationData} from '@mgp-fe/shared/core-api/mutations/user/consent-settings.ts';

export default function useCreateCustomerMutation(props?: UseCreateCustomerMutationProps) {
	const queryClient = useQueryClient();
	const [, setToken] = useLocalStorage<string|null>(AUTH_LOCAL_STORAGE_KEY, null);
	const recaptchaToken = useGetRecaptchaToken('invite_link_signup');

	return useMutation<AxiosResponse<Customer>, AxiosError<HydraError>, CreateCustomerMutationData>(
		keysResolver(endpoints.user.customers.list, 'post'),
		async (d) => coreApiClient.post<Customer>(
			endpoints.user.customers.list,
			{...d, ...(!d?.recaptchaToken ? {recaptchaToken: await recaptchaToken()} : {})},
		),
		{
			...props,
			onSuccess: async (result, variables, context) => {
				result.data.signToken && setToken(result.data.signToken);
				await queryClient.invalidateQueries(keysResolver(endpoints.me.index));
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

export type UseCreateCustomerMutationProps = UseMutationOptions<
	AxiosResponse<Customer>,
	AxiosError<HydraError>,
	CreateCustomerMutationData
>;

export interface CreateCustomerMutationData extends UpdateConsentSettingsMutationData {
	email: string;
	password?: string;
	name?: string | null;
	phone?: string | null;
	dateOfBirth?: string | null;
	recaptchaToken?: string;
}

export const CreateCustomerMutationDataSchema = z.object({
	email: z.string().email(),
	password: passwordSchema.optional(),
	name: z.string().refine(name => name.trim().split(/\s+/).length >= 2, {message: 'Name must contain at least 2 words'}),
	phone: phoneNumberSchema,
	dateOfBirth: z.date().optional(),
	contactConsent: z.object({
		transactionalEmails: z.boolean(),
		marketingEmails: z.boolean(),
		transactionalSms: z.boolean(),
		marketingSms: z.boolean(),
		transactionalCalls: z.boolean(),
		marketingCalls: z.boolean(),
		timezone: z.string().optional(),
		preferredLanguage: z.string().optional(),
	}).optional(),
});