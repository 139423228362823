import React, {useContext} from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FlipXIcon, FlipYIcon} from '@mgp-fe/designer/assets';
import {cn} from '@mgp-fe/shared/utils';
import {ContentImage} from '@mgp-fe/designer/state/domain';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import CircularSliderWrapper from '@mgp-fe/designer/modules/common/ui/components/circular-slider-wrapper.tsx';
import {Slider} from '@mgp-fe/shared/ui/slider.tsx';
import {DesignPreview} from '@mgp-fe/designer/modules/common';

export default function ImageControls({id, controlledContent}: ImageControlsProps) {
	const designerContext = useContext(DesignerContext);

	return <div className='flex flex-col gap-8 mt-4 sm:mt-8'>
		<div className='md:w-[20rem] h-20 sm:h-30 mx-auto'>
			<DesignPreview contentPosition={designerContext.content.findIndex(c => c.id === id)}/>
		</div>
		<div className='flex gap-8'>
			<div className='flex flex-col gap-2'>
				<label>Angle</label>
				<CircularSliderWrapper
					initialValue={controlledContent?.rotation ?? 0}
					onChange={value => {
						designerContext.dispatchContent({
							id,
							content: {
								rotation: value,
								type: 'image',
							},
						});
					}}
				/>
			</div>
			<div className='flex flex-col gap-2'>
				<label>Flip</label>
				<div className='flex gap-4 items-center mb-4'>
					<Button
						onClick={() => {
							designerContext.dispatchContent({
								id,
								content: {
									flipX: !controlledContent?.flipX,
									type: 'image',
								},
							});
						}}
						className='p-0 w-[3.75rem] h-[3.75rem] aspect-1'>
						<FlipXIcon className={cn(controlledContent?.flipX ? 'fill-muted' : '')}/>
					</Button>
					<p className='text-muted'>Horizontal</p>
				</div>
				<div className='flex gap-4 items-center'>
					<Button
						onClick={() => {
							designerContext.dispatchContent({
								id,
								content: {
									flipY: !controlledContent?.flipY,
									type: 'image',
								},
							});
						}}
						className='p-0 w-[3.75rem] h-[3.75rem] aspect-1'>
						<FlipYIcon className={cn(controlledContent?.flipY ? 'fill-muted' : '')}/>
					</Button>
					<p className='text-muted'>Veritcal</p>
				</div>
			</div>
		</div>
		<div className='grid w-full gap-2'>
			<label>Scale</label>
			<Slider max={300} step={1} defaultValue={[0]} value={[controlledContent?.scale ?? 100]}
				onValueChange={value => designerContext.dispatchContent({
					id,
					content: {
						scale: value[0],
						type: 'image',
					},
				})} orientation='horizontal' className='h-fit w-full ml-auto cursor-pointer'/>
		</div>
	</div>;
}

interface ImageControlsProps {
	id: number;
	controlledContent: ContentImage | undefined;
}