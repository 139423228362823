import {createContext} from 'react';
import {
	ContentReducerActions,
	ContentType,
	DesignerContextState, DesignerThicknessReducerActions, ScreenshotReducerActions,
	SectionColorReducerActions,
	SectionColorsType,
} from '@mgp-fe/designer/state/domain';



export const defaultSectionColors: SectionColorsType = {
	left3rd: {
		width: 630,
		xOffset: 0,
		color: '#52a452',
	},
	leftHalf: {
		width: 398,
		xOffset: 630,
		color: '#52a452',
	},
	rightHalf: {
		width: 398,
		xOffset: 1028,
		color: '#52a452',
	},
	right3rd: {
		width: 630,
		xOffset: 1426,
		color: '#52a452',
	},
};

export const defaultContent: ContentType = [
	{id: 0},
	{id: 1},
	{id: 2},
]; 

export default createContext<DesignerContextState>({
	sectionColors: defaultSectionColors,
	content: defaultContent,
	screenshot: {},
	thickness: {},
	dispatchSectionColors: ({ section, color }: SectionColorReducerActions) => ({
		section,
		color,
	}),
	dispatchContent: ({ position, content }: ContentReducerActions) => ({
		position,
		content,
	}),
	dispatchScreenshot: ({ type, image }: ScreenshotReducerActions) => ({
		type,
		image,
	}),
	dispatchThickness: ({ thickness }: DesignerThicknessReducerActions) => ({
		thickness,
	}),
});
