'use client';

import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosResponse} from 'axios';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {CartItemModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import {OrderItemModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {ProductOptionColor} from '@mgp-fe/shared/core-api/domain/product.ts';
import {CanvasData, ThicknessType} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';

export function useCreateCartItemMutation(props?: UseMutationOptions<AxiosResponse<CartItemModel>, unknown, CreateCartItemMutationRequest>) {
	const queryClient = useQueryClient();
	const {user} = useAuth();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.items.list),
		mutationFn: (data: CreateCartItemMutationRequest) => coreApiClient.post<CartItemModel>(endpoints.cart.my.items.list, {
			...data,
			customer: data.customer || user?.['@id'],
		}),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

export interface CreateCartItemMutationRequest {
	product: string;
	customer?: string;
	mouthguardDesign?: string;
	texture?: string;
	screenshot?: string;
	canvasData?: CanvasData;
	colors?: object;
	quantity?: number;
	selectedProductOptions?: ProductOptionColor;
	mouthguardThickness?: ThicknessType;
}

export function useReduceCartItemMutation(props?: UseUpdateCartItemMutationOptions) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.items.list),
		mutationFn: (oi: OrderItemModel) => coreApiClient.put<CartItemModel>(endpoints.cart.my.items.itemReduce(oi.id), {}),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

export function useRemoveItemFromCartMutation(props?: UseUpdateCartItemMutationOptions) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.items.list),
		mutationFn: (oi: OrderItemModel) => coreApiClient.delete<CartItemModel | null | undefined>(endpoints.cart.my.items.itemDelete(oi.id), {}),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

export type UseUpdateCartItemMutationOptions = UseMutationOptions<
	AxiosResponse<CartItemModel | null | undefined>,
	unknown,
	OrderItemModel
>;