import React, {useContext} from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {FormProvider, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import useCreateMouthguardDesign, {
	CreateMouthguardDesignRequest,
} from '@mgp-fe/shared/core-api/mutations/mouthguard-design/create.ts';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {CanvasData} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import useUpdateMouthguardDesign from '@mgp-fe/shared/core-api/mutations/mouthguard-design/update.ts';
import {useParams, useSearchParams} from 'react-router-dom';
import {useGetBase64FromContent} from '@mgp-fe/designer/hooks/get-base64-from-content.ts';
import {parseCanvasDataToOldFormat, parseColorsToOldData} from '@mgp-fe/designer/modules/common/utils';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import routes from '@mgp-fe/designer/router/routes.ts';
import useMouthguardDetailQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/detail.ts';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';

export function SaveDesignForm() {
	const {id} = useParams();
	const [query] = useSearchParams();
	const {user} = useAuth();
	const {notifyError} = useNotify();

	const designerContext = useContext(DesignerContext);
	const form = useForm<CreateMouthguardDesignRequest>({
		resolver: zodResolver(CreateDesignFormSchema),
	});

	const design = useMouthguardDetailQuery({
		id: id ?? '',
	});

	const createMouthguardMutation = useCreateMouthguardDesign({
		onSuccess: () => parent.postMessage('close-iframe', '*'),
	});
	const updateMouthguardMutation = useUpdateMouthguardDesign({
		onSuccess: () => parent.postMessage('close-iframe', '*'),
		onError: () => {
			if (design.data?.data.publicAccess === 'read_access') {
				return notifyError('This design can only be viewed & not modified.');
			} else {
				return notifyError('Design could not be updated.');
			}
		},
	});

	const contentImages = useGetBase64FromContent(designerContext.content);

	const onSubmit = async (d: CreateMouthguardDesignRequest) => {
		const colors = parseColorsToOldData(designerContext.sectionColors);

		const canvasData = parseCanvasDataToOldFormat({
			content: designerContext.content,
			images: contentImages,
		});

		const createMouthguardData = {
			name: d.name,
			type: window.location.pathname.includes(routes.clearDesigner.index) ? 'clear_custom_design' : 'full_custom_design',
			canvasData: canvasData as never as CanvasData,
			colors: colors,
			texture: designerContext.screenshot.textureScreenshot as string,
			screenshot: designerContext.screenshot.sceneScreenshot as string,
			thickness: designerContext.thickness.thickness,
			publicAccess: design.data?.data.publicAccess ? design.data?.data.publicAccess : query.has('teamId') ? 'read_access' : 'private_access',
			customer: (query.has('teamId') || design.data?.data.team || design.data?.data.affiliate || query.has('affiliateId')) ? undefined : user?.['@id'],
			team: query.get('teamId') ?? undefined,
			affiliate: query.get('affiliateId') ?? undefined,
		} as const;

		id ? updateMouthguardMutation.mutate({
			id: id,
			request: createMouthguardData,
		}) : createMouthguardMutation.mutate(createMouthguardData);
	};

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => onSubmit(d))}
			className='flex flex-col md:flex-row horizontal-labels items-center gap-4 max-w-[30rem] w-full px-4 md:px-0 mx-auto'>
			<TextInput
				type='text'
				defaultValue={design.data?.data.name ?? ''}
				placeholder='DESIGN NAME'
				className='!w-fit' name='name'/>
			<Button
				state={(createMouthguardMutation.status === 'loading' || updateMouthguardMutation.status === 'loading') ? 'loading' : 'idle'}
				className='!w-full'>
				Save Design
			</Button>

		</form>
	</FormProvider>;
}

const CreateDesignFormSchema = z.object({
	name: z.string().min(3),
});