import React, {useContext, useLayoutEffect, useMemo, useRef} from 'react';
import {CanvasTexture, Euler} from 'three';
import {useLoader} from '@react-three/fiber';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader.js';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {CANVAS_HEIGHT, CANVAS_WIDTH} from '@mgp-fe/designer/constants';
import {addContent, fillSection} from '@mgp-fe/designer/modules/common/utils';
import {contentPositions, screenshotActionTypes} from '@mgp-fe/designer/state/domain';

export function MouthguardModel() {
	const obj3D = useLoader(OBJLoader, '../middle.obj');

	const canvasRef = useRef(document.createElement('canvas'));
	const textureRef = useRef<CanvasTexture>(null);
	const designerContext = useContext(DesignerContext);

	useLayoutEffect(() => {
		const canvas = canvasRef.current;
		const texture = textureRef.current;

		canvas.width = CANVAS_WIDTH;
		canvas.height = CANVAS_HEIGHT;

		const context = canvas.getContext('2d');
		if (context && texture) {
			fillSection({context, section: designerContext.sectionColors.right3rd});
			fillSection({context, section: designerContext.sectionColors.rightHalf});
			fillSection({context, section: designerContext.sectionColors.leftHalf});
			fillSection({context, section: designerContext.sectionColors.left3rd});

			if (designerContext.content[contentPositions.LEFT])
				addContent({
					context,
					texture,
					position: {x: 320, y: 170},
					content: [designerContext.content[contentPositions.LEFT]],
				});

			if (designerContext.content[contentPositions.CENTER])
				addContent({
					context,
					texture,
					position: {x: CANVAS_WIDTH / 2, y: 160},
					content: [designerContext.content[contentPositions.CENTER]],
				});

			if (designerContext.content[contentPositions.RIGHT])
				addContent({
					context,
					texture,
					position: {x: CANVAS_WIDTH - 320, y: 170},
					content: [designerContext.content[contentPositions.RIGHT]],
				});

			texture.needsUpdate = true;

		}

		const timeout = setTimeout(() => {
			designerContext.dispatchScreenshot({
				type: screenshotActionTypes.SCENE,
				image: document.querySelector('canvas')?.toDataURL('image/png').replace('image/png', 'image/octet-stream') as string,
			});

			designerContext.dispatchScreenshot({
				type: screenshotActionTypes.TEXTURE,
				image: canvas.toDataURL('png'),
			});
		}, 100);

		return () => clearTimeout(timeout);

	}, [designerContext.sectionColors, designerContext.content]);


	const geometry = useMemo(() => {
		let g;
		obj3D.traverse((c: any) => {
			if (c.type === 'Mesh') {
				g = c.geometry;
			} 
		});
		return g;
	}, [obj3D]);


	// const materialProps = useControls({
	// 	anisotropy: { value: 0.19, min: 0, max: 1 },
	// 	anisotropyRotation: { value: 4.2, min: 0, max: 20 },
	// 	reflectivity: { value: 0.5, min: 0, max: 1 },
	// 	iridescence: { value: 0, min: 0, max: 1 },
	// 	iridescenceIOR: { value: 1.22, min: 1, max: 2.333 },
	// 	sheen: { value: 1, min: 0, max: 1 },
	// 	specularIntensity: { value: 0.24, min: 0, max: 1 },
	// 	thickness: { value: 0, min: 0, max: 20 },
	// 	roughness: { value: 0.1, min: 0, max: 1, step: 0.1 },
	// 	clearcoat: { value: 1, min: 0, max: 1, step: 0.1 },
	// 	clearcoatRoughness: { value: 0, min: 0, max: 1, step: 0.1 },
	// 	ior: { value: 1.25, min: 1, max: 2.3, step: 0.05 },
	// 	envMapIntensity: { value: 25, min: 0, max: 100, step: 1 },
	// 	color: '#ffffff',
	// 	attenuationTint: '#ffe79e',
	// });

	const materialProps = {
		anisotropy: 0.1,
		anisotropyRotation: 4.2,
		attenuationTint: '#ffe79e',
		clearcoat: 1,
		clearcoatRoughness: 0.1,
		color: '#ffffff',
		envMapIntensity: 25,
		ior: 2.3,
		iridescence: 1,
		iridescenceIOR: 2.333,
		reflectivity: 1,
		roughness: 0.1,
		sheen: 1,
		specularIntensity: 0.76,
		thickness: 0,
		metalness: 0.2,
	};

	/* eslint-disable */
	return <mesh rotation={new Euler(0, 3.2)} geometry={geometry} scale={0.15}>
		<meshPhysicalMaterial {...materialProps}>
			<canvasTexture
				ref={textureRef}
				attach='map'
				image={canvasRef.current}
			/>
		</meshPhysicalMaterial>
	</mesh>;
}