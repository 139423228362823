import {FieldErrors} from 'react-hook-form';

type ErrorStructure = {
	[key: string]: unknown;
	message?: string;
};

export default function getErrorMessage(errors: FieldErrors, name: string): string | null {
	const parts = name.split('.');
	let current: ErrorStructure | undefined = errors;

	for (const part of parts) {
		if (!current || typeof current !== 'object') return null;
		current = current[part as keyof typeof current] as ErrorStructure;
	}

	return current?.message || null;
}