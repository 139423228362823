
export type ContentType = {
    id: number;
    image?: ContentImage;
    text?: ContentText;
    athlete?: boolean;
}[]

export type ContentText = {
    label?: string;
    color?: string;
    size?: number;
    font?: string;
    type: 'text' | 'undefined'
}

export type ContentImage = {
    file?: File;
    flipX?: boolean;
    flipY?: boolean;
    rotation?: number;
    scale?: number;
    type: 'image' | 'undefined'
}

export interface ContentReducerActions {
    id: number;
    athlete?: boolean;
    position?: ContentPositionType;
    content?: ContentText | ContentImage;
}

export type ContentPositionType = typeof contentPositions[keyof typeof contentPositions];

export const contentPositions = {
	LEFT: 0,
	CENTER: 1,
	RIGHT: 2,
} as const;