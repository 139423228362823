import React, {useContext, useState} from 'react';
import {useDraggable} from '@dnd-kit/core';
import {AddModal, ImageDesigner, TextDesigner} from '@mgp-fe/designer/modules/common/ui/components';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {TriangleUpIcon, UploadImageIcon} from '@mgp-fe/designer/assets';
import {Checkbox} from '@mgp-fe/shared/ui/checkbox.tsx';

export function DraggableContentTeam(props: DraggableProps) {
	const [disabled, setDisabled] = useState(false);
	const designerContext = useContext(DesignerContext);
	const {attributes, listeners, setNodeRef, transform} = useDraggable({
		id: props.id,
		data: {
			permanentId: props.permanentId,
		},
		disabled: disabled,
	});

	const style = transform ? {
		transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
	} : undefined;

	const controlledContent = designerContext.content[designerContext.content.findIndex(c => c.id === props.permanentId)];

	return <div ref={setNodeRef} style={style} {...listeners} {...attributes} className='relative flex flex-col m-auto'>
		<div className='flex flex-col sm:flex-row items-center gap-2 mx-auto'>
			<Checkbox
				defaultChecked={controlledContent.athlete}
				checked={controlledContent.athlete}
				onCheckedChange={event => {
					console.log(event);
					designerContext.dispatchContent({
						id: props.permanentId,
						athlete: event as boolean,
					});
					designerContext.dispatchContent({
						id: props.permanentId,
						content: {
							type: event ? 'text' : 'undefined',
							label: 'Player',
							font: 'Bebas Neue',
							color: '#000000',
							size: 60,
						},
					});
				}}/>
			<h5 className='w-fit text-[10px] sm:text-[16px] md:text-[24px] select-none'>PLAYER AREA</h5>
		</div>
		<div className='block md:hidden'>
			<AddModal
				id={props.id}
				permanentId={props.permanentId}
				setDisabled={setDisabled}
				controlledContent={controlledContent}/>
		</div>
		<div className='hidden md:flex'>
			{!controlledContent.image?.file &&
				<TextDesigner id={props.permanentId} modalState={state => setDisabled(state)}/>}
			{!controlledContent.text?.label && !controlledContent.athlete &&
				<ImageDesigner id={props.permanentId} modalState={state => setDisabled(state)}/>}
			{!controlledContent.image?.file && !controlledContent.text?.label &&
				<div
					className='absolute h-[8.2rem] lg:tall:h-[10.5rem] tall:top-6 md:tall:top-9 lg:tall:top-8 left-[8rem] md:left-[7.9rem] lg:tall:left-[10rem] border-r border-dashed border-secondary rotate-[32deg]'/>
			}
			{!controlledContent.text?.label && controlledContent.athlete && <div
				className='flex flex-col w-32 h-32 lg:w-40 lg:h-40 relative select-none cursor-default opacity-50'>
				<TriangleUpIcon className='absolute h-full w-full z-[1]'/>
				<UploadImageIcon className='mx-auto mt-10 z-[2]'/>
				<h5 className='mx-auto pt-3 text-muted z-[2]'>IMAGE</h5>
			</div>}
		</div>
	</div>;
}

interface DraggableProps {
	id: number;
	permanentId: number;
}