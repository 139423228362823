import React from 'react';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import PhotoBoxerInFight from '@mgp-fe/shared/assets/stock-photos/boxers-in-fight.png';
import {cn, resolveEnvVar} from '@mgp-fe/shared/utils';
import {buttonVariants} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PhotoDoctorWithScanner from '@mgp-fe/shared/assets/stock-photos/doctor-blair-with-scanner.png';

export default function RegisterProxyContent() {
	// we are using this hack as we have Next.JS and React mixed in the same repository
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const athleteBackground = PhotoBoxerInFight.src || PhotoBoxerInFight || '';
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const doctorBackground = PhotoDoctorWithScanner.src || PhotoDoctorWithScanner || '';

	return <section className='flex flex-col gap-4'>
		<Card variant='transparent' spacing='none' className='bg-cover border-none overflow-hidden' style={{
			backgroundImage: `url(${athleteBackground})`,
		}}>
			<div className='bg-secondary-950/80 text-secondary-100 w-full h-full flex flex-col gap-2 p-6'>
				<h4>I&#39;m an athlete.</h4>
				<p className='text-muted'>I want to create a GARD design for FREE and buy now or later.</p>

				<a
					href={`${resolveEnvVar('customerAppUrl')}/onboarding`}
					className={cn(buttonVariants())} target='_blank' rel='noreferrer'>
					<FontAwesomeIcon icon='rocket' className='mr-2'/>
					Start here
				</a>
			</div>
		</Card>
		<Card variant='transparent' spacing='none' className='bg-cover border-none overflow-hidden' style={{
			backgroundImage: `url(${doctorBackground})`,
		}}>
			<div className='bg-secondary-950/80 text-secondary-100 w-full h-full flex flex-col gap-4 p-6'>
				<h5>I&#39;m a doctor, clinic manager or owner of a DSO.</h5>
				<p className='text-muted'>I want to become a partner and include GARD with my services.</p>

				<a
					href='https://partners.mouthguardclub.com/contact'
					className={cn(buttonVariants({variant: 'outline'}))} target='_blank' rel='noreferrer'>
					<FontAwesomeIcon icon='user-doctor' className='mr-2'/>
					Contact us
				</a>
			</div>
		</Card>
	</section>;
}
