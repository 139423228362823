import React from 'react';
import {DesignerClassic, SaveDesign, TeamLayout} from '@mgp-fe/designer/modules/common';
import {useParams} from 'react-router-dom';
import useMouthguardDetailQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/detail.ts';
import {useBackwardsCompatibility} from '@mgp-fe/designer/hooks';
import {useLoginWithToken} from '@mgp-fe/designer/hooks/login-with-token.ts';
import Container from '@mgp-fe/designer/modules/common/ui/layout/container.tsx';
import DesignerHeader from '@mgp-fe/designer/modules/common/ui/layout/designer-header.tsx';

export function TeamDesignerPage() {
	const {id} = useParams();

	const design = useMouthguardDetailQuery({
		id: id ?? '',
	});

	useLoginWithToken();

	useBackwardsCompatibility({
		canvasData: design.data?.data.canvasData,
		colors: design.data?.data.colors,
	});
	
	return <Container>
		<DesignerHeader allowDesignerThickness={false} allowDesignerSwitcher={false}/>
		<DesignerClassic/>
		<TeamLayout/>
		<SaveDesign/>
	</Container>;
}