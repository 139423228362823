import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function ToggleButton(props: ToggleButtonProps) {

	const [isChecked, setIsChecked] = useState(props.defaultChecked ?? false);

	useEffect(() => {
		props.onCheckedChange && props.onCheckedChange(isChecked);

	}, [isChecked]);


	function toggle() {
		setIsChecked(prevState => {
			return !prevState;
		});
	}

	return <div
		className='w-14 h-6 bg-background-500/50 border border-primary-500/30 flex rounded-full select-none cursor-pointer'
		onClick={toggle}>
		<div
			className='w-5 h-5 flex rounded-full my-auto bg-primary transition-all duration-300'
			style={{transform: isChecked ? 'translate(32px, 0px)' : 'translate(2px, 0px)'}}>
			{!isChecked ? <FontAwesomeIcon icon='unlock' className='w-[60%] h-[60%] m-auto text-background'/>
				:
				<FontAwesomeIcon icon='lock' className='w-[60%] h-[60%] m-auto text-background'/>}
		</div>
	</div>;
}

interface ToggleButtonProps {
	onCheckedChange?: (value: boolean) => void;
	defaultChecked?: boolean;
}