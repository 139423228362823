import {
	Toast,
	ToastClose,
	ToastDescription,
	ToastProvider,
	ToastTitle,
	ToastViewport,
} from '@mgp-fe/shared/ui/notifications/toast';
import {useToast} from '@mgp-fe/shared/ui/notifications/use-toast';
import React from 'react';

export function Toaster() {
	const {toasts} = useToast();

	return <ToastProvider>
		{toasts.map(function ({id, title, description, action, ...props}) {
			return <Toast key={id} {...props}>
				<div className='grid gap-1'>
					{title && <ToastTitle>{title}</ToastTitle>}
					{description && (
						<ToastDescription>{description}</ToastDescription>
					)}
				</div>
				{action}
				<ToastClose/>
			</Toast>;
		})}
		<ToastViewport/>
	</ToastProvider>;
}
