import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {z} from 'zod';

export default function useLoginMutation(props?: UseLoginMutationProps) {
	const queryClient = useQueryClient();
	const [, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);

	return useMutation<AxiosResponse<LoginMutationResult>, AxiosError<{ message?: string }>, LoginMutationData>(
		keysResolver(endpoints.authentication.login),
		(data: LoginMutationData) => coreApiClient.post<LoginMutationResult>(endpoints.authentication.login, data),
		{
			onSuccess: async (result, variables, context) => {
				setToken(result.data.token);
				await queryClient.invalidateQueries();
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

type UseLoginMutationProps = UseMutationOptions<AxiosResponse<LoginMutationResult>, AxiosError<{
	message?: string;
}>, LoginMutationData>;

export interface LoginMutationData {
	username: string;
	password: string;
	userType?: string;
	recaptchaToken?: string;
}

export const LoginMutationDataSchema = z.object({
	username: z.string().email(),
	password: z.string().min(4),
});

export interface LoginMutationResult {
	token: string;
}