'use client';

import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cn} from '@mgp-fe/shared/utils';

export default function Modal({
	isOpen,
	onClose,
	children,
	zIndex = 50,
	title = undefined,
	description = undefined,
	dialogPanelClassName = '',
}: ModalProps) {
	return <Transition appear show={isOpen} as={Fragment}>
		<Dialog as='div' className={`relative z-${zIndex}`} onClose={onClose}>
			<Transition.Child
				as={Fragment}
				enter='ease-out duration-300'
				enterFrom='opacity-0'
				enterTo='opacity-100'
				leave='ease-in duration-200'
				leaveFrom='opacity-100'
				leaveTo='opacity-0'>
				<div className='fixed inset-0 bg-black/70 backdrop-blur-md'/>
			</Transition.Child>

			<div className='fixed inset-0 overflow-y-auto'>
				<div className='flex min-h-full items-center justify-center p-4 text-center'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 scale-100'
						leaveTo='opacity-0 scale-95'>
						<Dialog.Panel className={cn(
							'relative w-full max-w-md transform rounded-2xl bg-background text-left align-middle shadow-lg shadow-primary-950 transition-all',
							dialogPanelClassName,
						)}>
							<Button onClick={onClose} size='icon' className={`absolute -right-3 -top-3 z-${zIndex}`}>
								<FontAwesomeIcon icon='times' className={`h-5 w-5 z-${zIndex}`}/>
							</Button>

							<div className='overflow-hidden p-4 sm:p-6 md:p-8'>
								{title ? <Dialog.Title as='h3' className='mb-5  text-primary'>{title}</Dialog.Title> : ''}
								{description ? <Dialog.Description className='mb-5 text-foreground/80'>{description}</Dialog.Description> : ''}
								{children}
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</div>
		</Dialog>
	</Transition>;
}

interface ModalProps {
	isOpen: boolean;
	children: React.ReactNode;
	onClose: () => void;
	title?: string;
	description?: string;
	zIndex?: number;
	dialogPanelClassName?: string;
}