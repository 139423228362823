import {CANVAS_HEIGHT} from '@mgp-fe/designer/constants';
import {SectionType} from '@mgp-fe/designer/state/domain';

export function fillSection({context, section}: FillSectionProps){
	context.rect(section.xOffset, 0, section.width, CANVAS_HEIGHT);
	context.fillStyle = section.color;
	context.fillRect(section.xOffset, 0, section.width, CANVAS_HEIGHT);
}

type FillSectionProps = {
    context:  CanvasRenderingContext2D;
    section: SectionType;
} 