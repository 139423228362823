export const MouthguardColors = [
	{
		color: '#d00c81',
		img: 'pink.jpg',
		name: 'Pink',
	},
	{
		color: '#4f2d8e',
		img: 'purple.jpg',
		name: 'Purple',
	},
	{
		color: '#222652',
		img: 'darkBlue.jpg',
		name: 'NavyBlue',
	},
	{
		color: '#15388a',
		img: 'opaqueBlue.jpg',
		name: 'Blue',
	},
	{
		color: '#039cdf',
		img: 'lightBlue.jpg',
		name: 'LightBlue',
	},
	{
		color: '#3e7a7c',
		img: 'turquoise.jpg',
		name: 'Turquoise',
	},
	{
		color: '#4a8943',
		img: 'green.jpg',
		name: 'Green',
	},
	// {
	// 	color: '#8bec4f',
	// 	img: 'neonGreen.jpg',
	// 	name: 'NeonGreen',
	// },
	{
		color: '#e1ca25',
		img: 'yellow.jpg',
		name: 'Yellow',
	},
	{
		color: '#e5ff00',
		img: 'neonYellow.jpg',
		name: 'NeonYellow',
	},
	{
		color: '#d2ad4d',
		img: 'gold.jpg',
		name: 'Gold',
	},
	{
		color: '#ce8242',
		img: 'orange.jpg',
		name: 'Orange',
	},
	{
		color: '#7c0a16',
		img: 'red.jpg',
		name: 'Red',
	},
	{
		color: '#551926',
		img: 'maroon.jpg',
		name: 'Maroon',
	},
	{
		color: '#000000',
		img: 'black.jpg',
		name: 'Black',
	},
	{
		color: '#7c7988',
		img: 'silver.jpg',
		name: 'Silver',
	},
	{
		color: '#ffffff',
		img: 'white.jpg',
		name: 'White',
	},
	{
		color: '#e5e5e5',
		img: 'clear.jpg',
		name: 'Clear',
	},
];