import React, {useContext, useEffect, useState} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {MouthguardColors, MouthguardSections} from '@mgp-fe/designer/constants';
import {cn} from '@mgp-fe/shared/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DesignerDialog} from '@mgp-fe/designer/modules/common/ui/components/designer-dialog.tsx';
import {RealColors} from '@mgp-fe/designer/assets/real-colors';
import {DesignerSection, SectionColorsType} from '@mgp-fe/designer/state/domain';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@mgp-fe/shared/ui/tooltip.tsx';

export function ClearDesignColorPicker({className}: ColorPickerProps) {
	const designerContext = useContext(DesignerContext);
	const displayModal = useToggle({initialState: false});
	const [color, setColor] = useState<string | undefined>('#4a8943');
	const [sectionColors, setSectionColors] = useState<SectionColorsType>();

	const pickColorHandler = ({col}: { col?: string }) => {
		if (col) {
			MouthguardSections.center.forEach(p => designerContext.dispatchSectionColors({
				section: p,
				color: col,
			}));
		}
	};

	useEffect(() => {
		if (displayModal.state) {
			setSectionColors(designerContext.sectionColors);
		}
		if (!displayModal.state) {
			setSectionColors(undefined);
		}
	}, [displayModal.state]);

	const handleClose = () => {
		(['leftHalf', 'rightHalf'] as DesignerSection[])
			.map(s => designerContext.dispatchSectionColors({
				section: s,
				color: sectionColors![s].color!,
			}));
		displayModal.off();
	};

	useEffect(() => {
		setColor(designerContext.sectionColors.rightHalf.color);
	}, []);

	return <>
		<Button
			onClick={() => displayModal.on()}
			className={className}
			variant={'outline'}
			icon={<FontAwesomeIcon icon='palette'/>}
		>Colors</Button>

		<DesignerDialog isOpen={displayModal.state} onClose={handleClose} title='Colors' titleIcon='palette'>
			<div className='hidden md:flex'>
				<TooltipProvider>
					<Tooltip delayDuration={0}>
						<TooltipTrigger className='ml-auto'>
							<FontAwesomeIcon icon='info-circle' fontVariant='far'/>
						</TooltipTrigger>
						<TooltipContent className='border-muted bg-background rounded-lg w-64 px-4 py-4'>
							<p className='text-muted'>
								Color represented in designer may be different than the finished product.
							</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
			<p className='md:hidden text-muted/50 text-xs mb-2'>
				Color represented in designer may be different than the finished product.
			</p>
			<div className='flex'>
				<div className='flex flex-col'>
					<div className='flex flex-wrap max-w-[25rem] gap-3 sm:gap-7'>
						{MouthguardColors.map(c => <div
							key={c.color}
							className={cn(
								'h-9 w-9 flex bg-transparent rounded-full cursor-pointer hover:scale-110 hover:border-2 hover:border-secondary duration-300',
								color === c.color ? 'border-2 border-primary scale-105 hover:scale-105 cursor-default' : '',
							)}
						>
							<div
								onClick={() => {
									setColor(c.color);
									pickColorHandler({col: c.color});
								}}
								className={cn(
									'h-8 w-8 m-auto rounded-full duration-300 ',
									color === c.color ? 'h-6 w-6' : '',
								)}
								style={{backgroundColor: c.color}}
							/>
						</div>)}
					</div>
				</div>

				<div className='m-auto w-fit'>
					<p className='w-24 sm:w-28 text-md text-muted text-center'>Selected</p>
					<div className='w-24 sm:w-28 aspect-1 bg-muted border-2 border-primary rounded-xl overflow-hidden'>
						{RealColors[MouthguardColors.find(c => c.color === color)
							?.img as keyof typeof RealColors]}
					</div>
					<p className='w-24 sm:w-28 text-md text-muted text-center'>{MouthguardColors.find(c => c.color === color)?.name}</p>
				</div>
			</div>

			<div className='flex'>

				<Button className='max-w-[16rem] mx-auto w-full mt-16' onClick={displayModal.off}>Save</Button>
			</div>
		</DesignerDialog>
	</>;
}

interface ColorPickerProps {
	className?: string;
}