import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {
	CanvasData,
	MouthguardDesignModel,
	MouthguardDesignPublicAccess,
	MouthguardDesignType,
} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function useUpdateMouthguardDesign(props?: UseUpdateMouthguardDesignProps) {

	return useMutation<AxiosResponse<MouthguardDesignModel>, AxiosError<HydraError>, UpdateMouthguardDesignData>({
		mutationKey: keysResolver(endpoints.mouthguardDesigns.item(''), 'post'),
		mutationFn: rq => coreApiClient.put<MouthguardDesignModel>(endpoints.mouthguardDesigns.item(rq.id), rq.request),
		...props,
	});
}

type UseUpdateMouthguardDesignProps = UseMutationOptions<
    AxiosResponse<MouthguardDesignModel>,
    AxiosError<HydraError>,
    UpdateMouthguardDesignData
>

export interface UpdateMouthguardDesignData {
    id: string;
    request: {
        type: MouthguardDesignType;
        name: string;
        canvasData: CanvasData;
        colors: object;
        publicAccess: MouthguardDesignPublicAccess;
        team?: Iri;
        customer?: Iri;
        affiliate?: Iri;
        screenshot: string;
        texture: string;
    }
}