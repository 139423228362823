import {ContentReducerActions, ContentType} from '@mgp-fe/designer/state/domain';

export function contentReducer(state: ContentType, action: ContentReducerActions) {
	const {position, id, content, athlete} = action;

	let tempState = state;
	const controlledObject = state.find(c => c.id === id);
	const indexOfControlledObject = state.findIndex(c => c.id === id);

	if (!controlledObject) return state;

	const changeContentPosition = (arr: ContentType, init: number, target: number) => {
		[arr[init], arr[target]] = [arr[target], arr[init]];
		return arr;
	};

	if (typeof position !== 'undefined') {
		tempState = changeContentPosition(state, indexOfControlledObject, position);
		return [...tempState];
	}

	if (typeof athlete !== 'undefined') {
		tempState[indexOfControlledObject] = {
			id,
			athlete: athlete,
		};
	}

	if (content){
		tempState[indexOfControlledObject] = {
			id,
			athlete: tempState[indexOfControlledObject].athlete,
			text: content.type === 'text' ? {...tempState[indexOfControlledObject].text, ...content} : undefined,
			image: content.type === 'image' ? {...tempState[indexOfControlledObject].image, ...content} : undefined,
		};
	}

	return [...tempState];
}