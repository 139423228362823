import React, {useContext, useEffect, useState} from 'react';
import TextColorPicker from '@mgp-fe/designer/modules/common/ui/components/text-designer/text-color-picker.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FontPicker from '@mgp-fe/designer/modules/common/ui/components/text-designer/font-picker.tsx';
import {ContentText} from '@mgp-fe/designer/state/domain';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {cn} from '@mgp-fe/shared/utils';
import {Slider} from '@mgp-fe/shared/ui/slider.tsx';
import {DesignPreview} from '@mgp-fe/designer/modules/common';

export function TextDesignerDialog({id, controlledContent, onCloseHandle}: TextDesignerDialogProps) {
	const designerContext = useContext(DesignerContext);
	const [display, setDisplay] = useState<'text' | 'font'>('text');
	const [originalFont, setOriginalFont] = useState<string | undefined | null>(null);

	const isAthleteArea = designerContext.content[designerContext.content.findIndex(c => c.id === id)].athlete;

	useEffect(() => {
		if (!originalFont && display === 'font') {
			setOriginalFont(controlledContent?.font);
		}
		if (!controlledContent?.label?.length) {
			designerContext.dispatchContent({
				id,
				content: {
					font: 'Bebas Neue',
					type: 'text',
					color: '#000000',
					size: 60,
				},
			});
		}
		if (isAthleteArea && !controlledContent?.label?.length) {
			designerContext.dispatchContent({
				id,
				content: {
					label: 'Player',
					type: 'text',
				},
				athlete: isAthleteArea,
			});
		}
	}, [display]);

	return <div className='flex flex-col w-full md:w-[50rem]'>
		<div className='md:w-[20rem] h-30 mx-auto'>
			<DesignPreview contentPosition={designerContext.content.findIndex(c => c.id === id)}/>
		</div>
		<div className='md:w-[20rem] mx-auto'>
			{{
				'text': <>
					<input
						onChange={(event) => {
							if (event.target.value) {
								designerContext.dispatchContent({
									id,
									content: {
										label: event.target.value,
										type: 'text',
									},
								});
							}
						}}
						disabled={isAthleteArea}
						placeholder='Your Name'
						maxLength={15}
						className={cn(
							'w-full text-center !mb-6 placeholder:!text-secondary-500/50 !text-[28px] !text-secondary !font-semibold',
							isAthleteArea ? '!text-secondary-500/50' : '',
						)}
						style={{fontFamily: controlledContent?.font}}
						defaultValue={controlledContent?.label}
						type='text'
					/>
					<TextColorPicker
						selectedColor={controlledContent?.color}
						onChooseHandler={color => designerContext.dispatchContent({
							id,
							content: {
								color: color,
								type: 'text',
							},
						})}/>

					<h4 className='text-center py-8 text-secondary uppercase'>Font Size</h4>

					<Slider max={100} step={1} defaultValue={[0]} value={[controlledContent?.size ?? 50]}
						onValueChange={value => designerContext.dispatchContent({
							id,
							content: {
								size: value[0],
								type: 'text',
							},
						})} orientation='horizontal' className='h-fit w-full ml-auto cursor-pointer'/>

					<Button
						onClick={() => setDisplay('font')}
						className='w-full my-8 text-secondary !rounded-none !no-underline border
								border-t-transparent border-x-transparent border-b-secondary'
						variant='link'
						iconPosition='right'
						icon={<FontAwesomeIcon icon='chevron-right'/>}
					>
						Fonts
					</Button>
					<div className='flex gap-2'>
						<Button className='block sm:hidden mx-auto w-20 !p-0 aspect-1' variant='destructive'
							onClick={() => onCloseHandle()}>
							<FontAwesomeIcon icon='xmark'/>
						</Button>
						<Button className='w-full' onClick={() => onCloseHandle(true)}>
							Save
						</Button>
					</div>
				</>,
				'font': <FontPicker
					onBackHandle={() => setDisplay('text')}
					defaultFont={controlledContent?.font}
					onPickHandle={font => {
						setDisplay('text');
						designerContext.dispatchContent({
							id,
							content: {
								font: font,
								type: 'text',
							},
						});
					}}
				/>,
			}[display]}
		</div>
	</div>;
}

interface TextDesignerDialogProps {
	id: number;
	controlledContent: ContentText | undefined;
	onCloseHandle: (isSave?: boolean) => void;
}