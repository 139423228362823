import React from 'react';
import {MouthguardTextColors} from '@mgp-fe/designer/constants/mouthguard-text-colors.ts';
import {cn} from '@mgp-fe/shared/utils';

export default function TextColorPicker({onChooseHandler, selectedColor}: TextColorPickerProps) {
	return <>
		<div className='flex flex-wrap gap-4 justify-center'>
			{MouthguardTextColors.slice(0, 5).map(c => <div
				key={c.color}
				className={cn(
					'h-10 w-10 flex bg-transparent rounded-full cursor-pointer hover:scale-110 hover:border-2 hover:border-secondary duration-300',
					selectedColor === c.color ? 'border-2 border-primary scale-105 hover:scale-105 cursor-default' : '',
				)}
			>
				<div
					onClick={() => onChooseHandler(c.color)}
					key={c.color}
					className={cn(
						'h-8 w-8 m-auto rounded-full',
					)}
					style={{backgroundColor: c.color}}
				/>
			</div>)}
		</div>
		<div className='flex flex-wrap gap-4 mt-4 justify-center'>
			{MouthguardTextColors.slice(5).map(c => <div
				key={c.color}
				className={cn(
					'h-10 w-10 flex bg-transparent rounded-full cursor-pointer hover:scale-110 hover:border-2 hover:border-secondary duration-300',
					selectedColor === c.color ? 'border-2 border-primary scale-105 hover:scale-105 cursor-default' : '',
				)}
			>
				<div
					onClick={() => onChooseHandler(c.color)}
					key={c.color}
					className={cn(
						'h-8 w-8 m-auto rounded-full',
					)}
					style={{backgroundColor: c.color}}
				/>
			</div>)}
		</div>
	</>;
}

interface TextColorPickerProps {
    onChooseHandler: (color: string) => void;
	selectedColor?: string;
}