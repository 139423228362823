import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {MouthguardDesignModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';

export default function useMouthguardDetailQuery(props: MouthguardDetailQueryProps) {
	return useQuery<AxiosResponse<MouthguardDesignModel>, AxiosError<HydraError>>({
		queryKey: keysResolver(endpoints.mouthguardDesigns.item(props.id), 'get', props.id),
		queryFn: () => coreApiClient.get(endpoints.mouthguardDesigns.item(props.id)),
		...props?.options,
	});
}

interface MouthguardDetailQueryProps {
    id: string;
    options?: UseQueryOptions<AxiosResponse<MouthguardDesignModel>, AxiosError<HydraError>>;
}