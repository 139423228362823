import {ContentType} from '@mgp-fe/designer/state/domain';
import {useEffect, useState} from 'react';

export function useGetBase64FromContent(content: ContentType) {
	const [contentImages, setContentImages] = useState<string[] | ArrayBuffer[] | null[]>([null, null, null]);

	useEffect(() => {
		function getBase64({file, index}: {file: File, index: number}) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				setContentImages(prevState => {
					const arr = prevState;
					arr[index] = reader.result;
					return arr;
				});
				return reader.result;
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};
		}

		content.map((c, index) => c.image?.file && getBase64({
			file: c.image.file,
			index: index,
		}));

	}, [content]);

	return contentImages;
}