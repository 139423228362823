import React, {useEffect, useState} from 'react';
import {ThicknessType} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import ThicknessSlider from '@mgp-fe/shared/modules/mouthguard-design/components/ThicknessSlider.tsx';

export default function DesignerThicknessModalContent({onChange, defaultValue}: DesignerThicknessModalContent) {
	const [sliderValue, setSliderValue] = useState<number>(0);

	useEffect(() => {
		if(defaultValue === 'thick')
			setSliderValue(2);
		if(defaultValue === 'normal')
			setSliderValue(1);
		if(defaultValue === 'thin')
			setSliderValue(0);
	}, []);


	useEffect(() => {
		let thickness: ThicknessType = 'normal';

		if (sliderValue === 2) thickness = 'thick';
		if (sliderValue === 1) thickness = 'normal';
		if (sliderValue === 0) thickness = 'thin';

		onChange(thickness);
	}, [sliderValue]);


	return <div>
		<ThicknessSlider sliderValue={sliderValue} sliderCallback={value => setSliderValue(value[0])}/>
	</div>;
}

interface DesignerThicknessModalContent {
	onChange: (value: ThicknessType) => void;
	defaultValue?: ThicknessType;
}