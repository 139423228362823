import React, {Dispatch} from 'react';
import {DesignerSection} from '@mgp-fe/designer/state/domain';
import MouthguardSectionIcon
	from '@mgp-fe/designer/modules/common/ui/components/color-picker/mouthguard-section-icon.tsx';
import {cn} from '@mgp-fe/shared/utils';

export default function PositionCard({color, position, cardPositions, label, setPosition}: PositionCardProps) {
	const positionChosen = JSON.stringify(position) === JSON.stringify(cardPositions);
	return <div
		onClick={() => setPosition(cardPositions)}
		className={cn(
			'flex flex-col w-full border border-muted rounded-lg items-center p-1 sm:p-2 cursor-pointer opacity-50 hover:opacity-100 duration-300',
			positionChosen ? 'opacity-100' : '',
		)}
	>
		<MouthguardSectionIcon sections={cardPositions} size={40} color={color}/>
		<p className={cn(
			'mb-auto mt-1 sm:mt-4',
			positionChosen ? 'text-primary' : 'text-muted/50',
		)}>{label}</p>
	</div>;
}

interface PositionCardProps {
	label: string;
	cardPositions: DesignerSection[];
	position: DesignerSection[] | undefined;
	setPosition: Dispatch<React.SetStateAction<DesignerSection[] | undefined>>;
	color: string | undefined;
}