export type ScreenshotType = {
    sceneScreenshot?: string | ArrayBuffer;
    textureScreenshot?: string | ArrayBuffer;
}

export type ScreenshotReducerActions = {
    type: ScreenshotActionType;
    image: string | ArrayBuffer;
}

export type ScreenshotActionType = typeof screenshotActionTypes[keyof typeof screenshotActionTypes]

export const screenshotActionTypes = {
	'SCENE': 'SCENE',
	'TEXTURE': 'TEXTURE',
} as const;