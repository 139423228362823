export type Iri = string;
export const AUTH_LOCAL_STORAGE_KEY = 'core:api:key:auth';
export const AUTH_IMPERSONATION_STORAGE_KEY = 'core:api:key:auth:impersonation';

export interface HydraListResponse<T extends HydraModel> {
	'@context': string;
	'@id': string;
	'@type': string;
	'hydra:member': T[];
	'hydra:totalItems': number;
	'hydra:search': Map<string, never>;
	'hydra:view': {
		'@id': string;
		'@type': string;
		'hydra:first'?: string;
		'hydra:last'?: string;
		'hydra:previous'?: string;
		'hydra:next'?: string;
	};
}


export interface HydraModel {
	'@context': string;
	'@id': Iri;
	'@type': string;
	id: string;
	createdAt?: Date;
	updatedAt?: Date;
	deletedAt?: Date;
}

export interface HydraError {
	'hydra:title': string;
	'hydra:description': string;
	detail?: string;
}

export type ArchivableListParams = { archive?: boolean };

export interface CollectionParams {
	itemsPerPage?: number;
	page?: number;
	pagination?: boolean;
	archive?: boolean;
	order?: {
		createdAt?: 'asc' | 'desc';
	};
}

export interface Money {
	amount: number;
	currency: string;
}

export const ORDER_ASC = 'ASC';
export const ORDER_DESC = 'DESC';
export type OrderFilter = typeof ORDER_ASC | typeof ORDER_DESC;