'use client'

import React from 'react';
import './GoogleSignButton.scss';
import useAuth from "@mgp-fe/shared/modules/auth/hooks/useAuth.ts";
import coreApiClient from "@mgp-fe/shared/core-api/client.ts";
import useNotify from "@mgp-fe/shared/ui/notifications/use-notify.ts";
import routes from "@mgp-fe/shared/modules/onboarding/routes.ts";
import getClientEnv from "@mgp-fe/shared/utils/env-var-resolver.ts";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";

export default function GoogleSignButton({
	type = 'standard',
	size = 'large',
	shape = 'rectangular',
	theme = 'outline',
	width = 310,
	successCallback,
}: GoogleSignButtonProps) {
	const { loginWithToken } = useAuth();
	const { notifyError } = useNotify();

	return <GoogleLogin
		onSuccess={async (credentialResponse: CredentialResponse) => {
			const OAuthLogin = await coreApiClient.post<{
				token: string,
				finishedOnboarding?: boolean
			}>('/authentication/login/oauth', {
				provider: 'google',
				credential: credentialResponse.credential,
			});

			loginWithToken(OAuthLogin.data.token);
			successCallback?.();

			if (OAuthLogin.data.finishedOnboarding !== undefined && !OAuthLogin.data.finishedOnboarding) {
				window.location.href = getClientEnv('customerAppUrl') + routes.onboarding + '?usingOauth=1'
			}

		}}
		onError={() => {
			notifyError({
				message: 'Login with google failed',
			})
		}}
		type={type}
		size={size}
		shape={shape}
		theme={theme}
		width={width}
	/>;
}

interface GoogleSignButtonProps {
	type?: 'icon' | 'standard';
	size?: 'small' | 'medium' | 'large';
	shape?: 'square' | 'circle' | 'pill' | 'rectangular';
	theme?: 'outline' | 'filled_blue' | 'filled_black';
	width?: number;
	successCallback?: () => void,
}