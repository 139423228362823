import React, {PropsWithChildren, useMemo} from 'react';
import NotificationContext from './NotificationsContext.ts';
import {NotificationsContextState} from '@mgp-fe/shared/ui/notifications/domain.ts';
import {Toaster} from './toaster';
import {useToast} from '@mgp-fe/shared/ui/notifications/use-toast.ts';

export default function NotificationsProvider({children}: PropsWithChildren) {
	const {toast} = useToast();

	const value = useMemo<NotificationsContextState>(() => ({
		notifications: [],
		notify: (message) => toast({
			variant: message.type,
			title: message.title,
			description: message.message,
		}),
		notifySuccess: (message) => toast({
			variant: 'success',
			title: typeof message === 'string' ? undefined : message.title,
			description: typeof message === 'string' ? message : message.message,
		}),
		notifyError: (message) => toast({
			variant: 'error',
			title: typeof message === 'string' ? undefined : message.title,
			description: typeof message === 'string' ? message : message.message,
		}),
		notifyInfo: (message) => toast({
			variant: 'info',
			title: typeof message === 'string' ? undefined : message.title,
			description: typeof message === 'string' ? message : message.message,
		}),
		notifyWarning: (message) => toast({
			variant: 'warning',
			title: typeof message === 'string' ? undefined : message.title,
			description: typeof message === 'string' ? message : message.message,
		}),
	}), [toast]);

	return <NotificationContext.Provider value={value}>
		{children}
		<Toaster/>
	</NotificationContext.Provider>;
}