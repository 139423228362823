import React from 'react';
import {DesignerSection} from '@mgp-fe/designer/state/domain';
import {MouthguardSections} from '@mgp-fe/designer/constants';

export default function MouthguardSectionIcon({sections, color = '#838383FF', size}: MouthguardSectionIconProps) {
	if (JSON.stringify(sections) === JSON.stringify(MouthguardSections.left3rd))
		return <svg height={size} className='mt-auto' viewBox='0 0 270.18 230.78'>
			<defs/>
			<path
				style={{fill: '#888888AE'}}
				d='M324.79,105.22c.16-15.47-1.71-29-8-41.7-9.71-19.44-28.2-27.32-48.84-20.79-9.92,3.14-16.73,9.86-21.28,19.09-5.53,11.23-7.36,23.38-8.81,35.61-2.31,19.64-2.41,39.51-6,59-1.53,8.37-3.74,16.52-7.92,24-7.49,13.48-19.33,18.94-34.3,18.94-15.13,0-27.19-5.41-34.68-19.15a60.49,60.49,0,0,1-3.26-7.11L84.37,207.91a170.29,170.29,0,0,0,26.34,31.34c23,21.65,51,33.05,83,31.93,39.8-1.4,70.6-20.1,93.85-51.73,16.23-22.09,24.57-47.68,31.12-73.94C322.06,131.78,325.16,118,324.79,105.22Z'
				transform='translate(-54.64 -40.48)'
			/>
			<path
				style={{fill: color}}
				d='M146,147.8c-2.72-20.94-2.38-42.18-5.94-63a97.13,97.13,0,0,0-6.55-22.41C122.15,36.11,83.28,33,67.15,56.59c-8.06,11.78-12.53,25-12.51,38.87,0,41.2,8.28,79.51,29.73,112.45l67.33-34.75C148.57,165,147.12,156.46,146,147.8Z'
				transform='translate(-54.64 -40.48)'
			/>
		</svg>;

	if (JSON.stringify(sections) === JSON.stringify(MouthguardSections.leftHalf))
		return <svg height={size} className='mt-auto' viewBox='0 0 270.18 230.77'>
			<defs/>
			<path
				style={{fill: '#888888AE'}}
				d='M324.79,105.22c.16-15.47-1.71-29-8-41.7-9.71-19.44-28.2-27.32-48.84-20.79-9.92,3.14-16.73,9.86-21.28,19.09-5.53,11.23-7.36,23.38-8.81,35.61-2.31,19.64-2.41,39.51-6,59-1.53,8.37-3.74,16.52-7.92,24-7.43,13.37-19.15,18.84-33.94,18.93v71.84c1.21,0,2.43,0,3.66-.07,39.8-1.4,70.6-20.1,93.85-51.73,16.23-22.09,24.57-47.68,31.12-73.94C322.06,131.78,325.16,118,324.79,105.22Z'
				transform='translate(-54.64 -40.48)'
			/>
			<path
				style={{fill: color}}
				d='M189.64,199.42c-15.13,0-27.19-5.41-34.68-19.15-5.51-10.12-7.5-21.23-9-32.47-2.72-20.94-2.38-42.18-5.94-63a97.13,97.13,0,0,0-6.55-22.41C122.15,36.11,83.28,33,67.15,56.59c-8.06,11.78-12.53,25-12.51,38.87.07,55.07,14.74,105,56.07,143.79,22.16,20.82,48.84,32.16,79.29,32V199.41Z'
				transform='translate(-54.64 -40.48)'
			/>
		</svg>;

	if (JSON.stringify(sections) === JSON.stringify(MouthguardSections.full))
		return <svg
			height={size} className='mt-auto'
			style={{fill: color}}
			viewBox='0 0 270.18 230.78'>
			<defs/>
			<path
				d='M324.79,105.22c.16-15.47-1.71-29-8-41.7-9.71-19.44-28.2-27.32-48.84-20.79-9.92,3.14-16.73,9.86-21.28,19.08-5.53,11.24-7.36,23.39-8.81,35.62-2.31,19.64-2.41,39.51-6,59-1.53,8.37-3.74,16.52-7.92,24-7.49,13.48-19.33,18.94-34.3,18.94-15.13,0-27.19-5.41-34.68-19.15-5.51-10.12-7.5-21.23-9-32.47-2.72-20.94-2.38-42.18-5.94-63a97.13,97.13,0,0,0-6.55-22.41C122.15,36.11,83.28,33,67.15,56.59c-8.06,11.78-12.53,25-12.51,38.87.07,55.07,14.74,105,56.07,143.79,23,21.65,51,33.05,83,31.93,39.8-1.4,70.6-20.1,93.85-51.73,16.23-22.09,24.57-47.68,31.12-73.94C322.06,131.78,325.16,118,324.79,105.22Z'
				transform='translate(-54.64 -40.48)'
			/>
		</svg>;

	if (JSON.stringify(sections) === JSON.stringify(MouthguardSections.center))
		return <svg
			height={size} className='mt-auto'
			viewBox='0 0 270.18 230.77'>
			<defs/>
			<path
				style={{fill: '#888888AE'}}
				d='M324.77,100.54c0-1.69.05-3.38.05-5.08a65.59,65.59,0,0,0-5.7-26.66c-.71-1.78-1.48-3.54-2.35-5.28-8.86-17.74-25-25.84-43.46-22.17a40.74,40.74,0,0,0-6.78,1.86l-.74.28-.8.32c-.52.22-1,.45-1.54.69l-.49.23c-.39.19-.78.4-1.17.61a34.92,34.92,0,0,0-15.84,17,97.13,97.13,0,0,0-6.55,22.41A317.18,317.18,0,0,0,236.06,117c-1,13.19-1.81,26.4-4.2,39.45-1.53,8.37-3.74,16.52-7.92,24-7.49,13.48-19.33,18.94-34.3,18.94-15.13,0-27.19-5.41-34.68-19.15a60.49,60.49,0,0,1-3.26-7.11c-3.13-8.12-4.58-16.7-5.7-25.36-2.72-20.94-2.38-42.18-5.94-63a97.13,97.13,0,0,0-6.55-22.41C122.15,36.11,83.28,33,67.15,56.59c-8.06,11.78-12.53,25-12.51,38.87,0,41.2,8.28,79.51,29.73,112.45a170.29,170.29,0,0,0,26.34,31.34c23,21.65,51,33.05,83,31.93,39.8-1.4,70.6-20.1,93.85-51.73a139.22,139.22,0,0,0,7.71-11.73c16.51-25.44,25.15-54.07,28.25-84.75a96.65,96.65,0,0,0,1.32-17.75C324.81,103.64,324.8,102.08,324.77,100.54Z'
				transform='translate(-54.64 -40.48)'
			/>
			<path
				style={{fill: color}}
				d='M223.94,180.48c-7.49,13.48-19.33,18.94-34.3,18.94-15.13,0-27.19-5.41-34.68-19.15a60.49,60.49,0,0,1-3.26-7.11L84.37,207.91a170.29,170.29,0,0,0,26.34,31.34c23,21.65,51,33.05,83,31.93,39.8-1.4,70.6-20.1,93.85-51.73a139.22,139.22,0,0,0,7.71-11.73l-.12.19-67.33-34.75'
				transform='translate(-54.64 -40.48)'
			/>
		</svg>;
	if (JSON.stringify(sections) === JSON.stringify(MouthguardSections.rightHalf))
		return <svg
			height={size} className='mt-auto scale-x-[-1]'
			viewBox='0 0 270.18 230.77'>
			<defs/>
			<path
				style={{fill: '#888888AE'}}
				d='M324.79,105.22c.16-15.47-1.71-29-8-41.7-9.71-19.44-28.2-27.32-48.84-20.79-9.92,3.14-16.73,9.86-21.28,19.09-5.53,11.23-7.36,23.38-8.81,35.61-2.31,19.64-2.41,39.51-6,59-1.53,8.37-3.74,16.52-7.92,24-7.43,13.37-19.15,18.84-33.94,18.93v71.84c1.21,0,2.43,0,3.66-.07,39.8-1.4,70.6-20.1,93.85-51.73,16.23-22.09,24.57-47.68,31.12-73.94C322.06,131.78,325.16,118,324.79,105.22Z'
				transform='translate(-54.64 -40.48)'
			/>
			<path
				style={{fill: color}}
				d='M189.64,199.42c-15.13,0-27.19-5.41-34.68-19.15-5.51-10.12-7.5-21.23-9-32.47-2.72-20.94-2.38-42.18-5.94-63a97.13,97.13,0,0,0-6.55-22.41C122.15,36.11,83.28,33,67.15,56.59c-8.06,11.78-12.53,25-12.51,38.87.07,55.07,14.74,105,56.07,143.79,22.16,20.82,48.84,32.16,79.29,32V199.41Z'
				transform='translate(-54.64 -40.48)'
			/>
		</svg>;

	if (JSON.stringify(sections) === JSON.stringify(MouthguardSections.right3rd))
		return <svg height={size} className='mt-auto scale-x-[-1]' viewBox='0 0 270.18 230.78'>
			<defs/>
			<path
				style={{fill: '#888888AE'}}
				d='M324.79,105.22c.16-15.47-1.71-29-8-41.7-9.71-19.44-28.2-27.32-48.84-20.79-9.92,3.14-16.73,9.86-21.28,19.09-5.53,11.23-7.36,23.38-8.81,35.61-2.31,19.64-2.41,39.51-6,59-1.53,8.37-3.74,16.52-7.92,24-7.49,13.48-19.33,18.94-34.3,18.94-15.13,0-27.19-5.41-34.68-19.15a60.49,60.49,0,0,1-3.26-7.11L84.37,207.91a170.29,170.29,0,0,0,26.34,31.34c23,21.65,51,33.05,83,31.93,39.8-1.4,70.6-20.1,93.85-51.73,16.23-22.09,24.57-47.68,31.12-73.94C322.06,131.78,325.16,118,324.79,105.22Z'
				transform='translate(-54.64 -40.48)'
			/>
			<path
				style={{fill: color}}
				d='M146,147.8c-2.72-20.94-2.38-42.18-5.94-63a97.13,97.13,0,0,0-6.55-22.41C122.15,36.11,83.28,33,67.15,56.59c-8.06,11.78-12.53,25-12.51,38.87,0,41.2,8.28,79.51,29.73,112.45l67.33-34.75C148.57,165,147.12,156.46,146,147.8Z'
				transform='translate(-54.64 -40.48)'
			/>
		</svg>;

	return <></>;

}

interface MouthguardSectionIconProps {
    sections: DesignerSection[];
    color: string | undefined;
    size?: number;
}