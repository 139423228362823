import React, {useEffect, useState} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {SaveDesignForm} from '@mgp-fe/designer/modules/common/save-design/save-design-form.tsx';
import {SaveWithEmailForm} from '@mgp-fe/designer/modules/common/save-design/save-with-email-form.tsx';
import {useParams} from 'react-router-dom';
import useMouthguardDetailQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/detail.ts';

export function SaveDesign() {
	const {id} = useParams();
	const {user, isUserLoading, isUserLoaded} = useAuth();
	const [hasUser, setHasUser] = useState(false);
	const [firstTime, setFirstTime] = useState(false);
	const design = useMouthguardDetailQuery({
		id: id ?? '',
	});

	useEffect(() => {
		if (isUserLoaded && !firstTime){
			setFirstTime(true);
			setHasUser(!!user);
		}
	}, [isUserLoading]);

	if (hasUser || design.data?.data.team) return <SaveDesignForm/>;
	if (!hasUser) return <SaveWithEmailForm/>;
}