import React, {PropsWithChildren, Reducer, useReducer} from 'react';
import DesignerContext, {defaultContent, defaultSectionColors} from '@mgp-fe/designer/state/designer-context.ts';

import {
	contentReducer,
	screenshotReducer,
	sectionColorsReducer,
	thicknessReducer,
} from '@mgp-fe/designer/state/reducers';
import {
	ContentReducerActions,
	ContentType, DesignerThicknessReducerActions, DesignerThicknessType, ScreenshotReducerActions, ScreenshotType,
	SectionColorReducerActions,
	SectionColorsType,
} from '@mgp-fe/designer/state/domain';

export default function DesignerProvider({children}: PropsWithChildren){

	const [sectionColors, dispatchSectionColors] = useReducer<Reducer<SectionColorsType, SectionColorReducerActions>>(sectionColorsReducer, defaultSectionColors);
	const [content, dispatchContent] = useReducer<Reducer<ContentType, ContentReducerActions>>(contentReducer, defaultContent);
	const [screenshot, dispatchScreenshot] = useReducer<Reducer<ScreenshotType, ScreenshotReducerActions>>(screenshotReducer, {});
	const [thickness, dispatchThickness] = useReducer<Reducer<DesignerThicknessType, DesignerThicknessReducerActions>>(thicknessReducer, {});

	return <DesignerContext.Provider value={{sectionColors, content, screenshot, thickness, dispatchSectionColors, dispatchContent, dispatchScreenshot, dispatchThickness }}>
		{children}
	</DesignerContext.Provider>;
}

