import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cn} from '@mgp-fe/shared/utils';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export function DesignerDialog({
	isOpen,
	onClose,
	children,
	zIndex = 50,
	title = undefined,
	titleIcon = undefined,
	description = undefined,
	dialogPanelClassName = '',
}: ModalProps) {
	return <Transition appear show={isOpen} as={Fragment}>
		<Dialog as='div' className={`relative z-${zIndex}`} onClose={onClose}>
			<Transition.Child
				as={Fragment}
				enter='ease-out duration-300'
				enterFrom='opacity-0'
				enterTo='opacity-100'
				leave='ease-in duration-200'
				leaveFrom='opacity-100'
				leaveTo='opacity-0'>
				<div className='fixed inset-0'/>
			</Transition.Child>

			<div className='fixed inset-0 overflow-y-auto'>
				<div className='flex min-h-full items-end justify-center sm:p-4 text-center'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-1/2 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 scale-100'
						leaveTo='opacity-0 translate-y-1/2 sm:translate-y-0 sm:scale-95'>
						<Dialog.Panel className={cn(
							'relative w-full h-fit max-h-full sm:h-full sm:w-fit sm:mb-[5%] transform rounded-md bg-background text-left align-middle transition-all',
							dialogPanelClassName,
						)}>
							<div
								className='flex w-full min-h-12 sm:min-h-[5rem] bg-secondary rounded-t-md items-center'>
								{title ? <Dialog.Title as='h5' className='text-muted pl-[2rem] uppercase'>
									{titleIcon && <><FontAwesomeIcon icon={titleIcon}/>&nbsp;</>}

									{title}
								</Dialog.Title> : ''}
								<Button onClick={onClose} size='icon' variant='link'
									className={`ml-auto pr-[2rem] z-${zIndex}`}>
									<FontAwesomeIcon icon='times' className={`h-5 w-5 text-muted z-${zIndex}`}/>
								</Button>
							</div>

							<div className='overflow-hidden bg-[#0D130B] rounded-b-md p-4 sm:p-6 md:p-8'>
								{description ? <Dialog.Description
									className='mb-5 text-foreground/80'>{description}</Dialog.Description> : ''}
								{children}
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</div>
		</Dialog>
	</Transition>;
}

interface ModalProps {
	isOpen: boolean;
	children: React.ReactNode;
	onClose: () => void;
	title?: string;
	titleIcon?: IconProp;
	description?: string;
	zIndex?: number;
	dialogPanelClassName?: string;
}