import React, {useContext} from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import useMouthguardDetailQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/detail.ts';
import {useLocation, useParams} from 'react-router-dom';
import {MouthguardColors, MouthguardSections} from '@mgp-fe/designer/constants';
import {ContentPositionType} from '@mgp-fe/designer/state/domain';
import {setContentFromOldDesigner} from '@mgp-fe/designer/hooks';
import {cn} from '@mgp-fe/shared/utils';

export function StartOverButton({className}: StartOverButtonProps) {
	const {id} = useParams();
	const location = useLocation();
	const designerContext = useContext(DesignerContext);
	const design = useMouthguardDetailQuery({
		id: id ?? '',
	});

	const onStartOver = () => {
		MouthguardSections.full.forEach(p => designerContext.dispatchSectionColors({
			section: p,
			color: MouthguardColors.find(c => c.name === 'Green')?.color ?? '',
		}));

		if (location.pathname.includes('clear-designer')) {
			[...MouthguardSections.left3rd, ...MouthguardSections.right3rd].forEach(p => designerContext.dispatchSectionColors({
				section: p,
				color: MouthguardColors.find(c => c.name === 'Clear')?.color ?? '',
			}));
		}

		[...(new Array(3))].map((_, index) => designerContext.dispatchContent({
			id: index,
			content: {
				type: 'undefined',
			},
		}));

		[...(new Array(3))].map((_, index) => designerContext.dispatchContent({
			id: index,
			position: index as ContentPositionType,
			athlete: undefined,
		}));

		designerContext.dispatchThickness({
			thickness: 'thin',
		});

		if (design) {
			const colors: any = design.data?.data.colors;
			const thickness = design.data?.data.thickness;
			const canvasData = design.data?.data.canvasData;


			if (!!colors && !!colors.left3rd) {
				designerContext.dispatchSectionColors({
					section: 'right3rd',
					color: colors.left3rd.color,
				});
			}

			if (!!colors && !!colors.leftHalf) {
				designerContext.dispatchSectionColors({
					section: 'rightHalf',
					color: colors.leftHalf.color,
				});
			}

			if (!!colors && !!colors.rightHalf) {
				designerContext.dispatchSectionColors({
					section: 'leftHalf',
					color: colors.rightHalf.color,
				});
			}

			if (!!colors && !!colors.right3rd) {
				designerContext.dispatchSectionColors({
					section: 'left3rd',
					color: colors.right3rd.color,
				});
			}

			if (thickness) {
				designerContext.dispatchThickness({
					thickness,
				});
			}

			if (canvasData)
				setContentFromOldDesigner(canvasData, designerContext);
		}

	};

	return <>
		<Button
			onClick={onStartOver}
			className={cn(
				'hidden md:tall:block pointer-events-auto border-primary-500/50 py-mini text-primary w-fit md:ml-auto',
				className,
			)}
			variant='outline'>START OVER</Button>
		<Button
			onClick={onStartOver}
			className={cn(
				'block md:tall:hidden pointer-events-auto border-primary-500/50 py-mini text-primary w-fit md:ml-auto',
				className,
			)}
			variant='link'>START OVER</Button>
	</>;
}

interface StartOverButtonProps {
	className?: string;
}