export type SectionColorsType = {
    right3rd: SectionType;
    rightHalf: SectionType;
    leftHalf: SectionType;
    left3rd: SectionType;
}

export type SectionType = {
    width: number;
    xOffset: number;
    color: string;
}

export interface SectionColorReducerActions {
    section?: DesignerSection;
    color: string;
}

export type DesignerSection = typeof designerSections[keyof typeof designerSections];

export const designerSections = {
	RIGHT_3RD: 'right3rd',
	RIGHT_HALF: 'rightHalf',
	LEFT_HALF: 'leftHalf',
	LEFT_3RD: 'left3rd',
} as const;

export type MouthguardSectionType = typeof mouthguardSectionTypes[keyof typeof mouthguardSectionTypes];

export const mouthguardSectionTypes = {
	full: 'FULL_COLOR',
	twoColors: 'TWO_COLORS',
	threeColors: 'THREE_COLORS',
} as const;