'use client';

import * as React from 'react';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RegisterOptions, useFormContext, useFormState} from 'react-hook-form';
import {omit} from 'lodash';
import {cn} from '@mgp-fe/shared/utils';
import getErrorMessage from '@mgp-fe/shared/ui/form/error-message.ts';

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string | React.ReactNode;
	name: string;
	type?: 'text' | 'password' | 'email' | 'tel' | 'date';
	labelClassName?: string;
	registerOptions?: RegisterOptions;
}

const TextInput = (props: InputProps) => {
	const form = useFormContext();
	const {errors} = useFormState({
		control: form.control,
	});

	const inputProps = omit({...props}, ['formControl', 'label', 'labelClassName']);

	const [showPassword, setShowPassword] = useState(false);

	const errorMessage = getErrorMessage(errors, props.name);

	return <label className={cn(
		'flex flex-col gap-mini mt-small relative w-full',
		props.labelClassName || '',
	)}>
		{props.label ? <span>{props.label}</span> : ''}

		<input
			{...inputProps}
			{...form.register(props.name, props.registerOptions)}
			type={props.type === 'password' && showPassword ? 'text' : (props.type || 'text')}
			className={cn(
				...(inputProps.className ? inputProps.className : ''),
				'w-full',
			)}
		/>
		{props.type === 'password' ? <button
			type='button' className='absolute top-11 right-4 text-primary text-sm'
			onClick={() => setShowPassword(!showPassword)}>
			<FontAwesomeIcon icon={showPassword ? 'eye' : 'eye-slash'}/>
		</button>: ''}
		{(props.name && errorMessage) ? <span
			className='absolute -top-2 right-0 w-3/4 bg-destructive/80 text-sm text-destructive-foreground px-2 py-1 rounded-md'>
			{errorMessage as string}
		</span> : ''}
	</label>;
};
TextInput.displayName = 'FormInput';

export {TextInput};
