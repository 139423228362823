import {useContext, useEffect} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {CanvasData, ThicknessType} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {DesignerContextState} from '@mgp-fe/designer/state/domain';

export const useBackwardsCompatibility = ({canvasData, colors, thickness}:  UseBackwardsCompatibilityProps) => {
	const designerContext = useContext(DesignerContext);

	// const {data, canvasParameters} = canvasData;

	useEffect(() => {
		if (!!colors && !!colors.left3rd) {
			designerContext.dispatchSectionColors({
				section: 'right3rd',
				color: colors.left3rd.color,
			});
		}

		if (!!colors && !!colors.leftHalf) {
			designerContext.dispatchSectionColors({
				section: 'rightHalf',
				color: colors.leftHalf.color,
			});
		}

		if (!!colors && !!colors.rightHalf) {
			designerContext.dispatchSectionColors({
				section: 'leftHalf',
				color: colors.rightHalf.color,
			});
		}

		if (!!colors && !!colors.right3rd) {
			designerContext.dispatchSectionColors({
				section: 'left3rd',
				color: colors.right3rd.color,
			});
		}

		if (canvasData)
			setContentFromOldDesigner(canvasData, designerContext);

		if (thickness) {
			designerContext.dispatchThickness({
				thickness,
			});
		}
	}, [colors]);



};

export const setContentFromOldDesigner = (canvasData: CanvasData | undefined, designerContext: DesignerContextState) => {

	canvasData?.data.map(async cd => {
		const position = {
			left: 0,
			center: 1,
			right: 2,
		}[cd.position];

		const type = {
			'i-text': 'text',
			image: 'image',
		}[cd.type];

		if (type === 'image' && cd.imagePath) {
			const response = await fetch(cd.imagePath);
			// here image is url/location of image
			const blob = await response.blob();
			const file = new File([blob], 'image.jpg', {type: blob.type});
			designerContext.dispatchContent({
				id: position,
				content: {
					type: 'image',
					rotation: cd.rotation,
					flipX: cd.flipX,
					flipY: cd.flipY,
					scale: cd.scale,
					file,
				},
			});
		}

		if (type === 'text') {
			const athleteContent = cd.cacheKey === 'playerName' || cd.athleteContent;
			designerContext.dispatchContent({
				id: position,
				athlete: athleteContent,
				content: {
					type: 'text',
					label: cd.text,
					size: cd.fontSize! * 3,
					font: cd.fontFamily,
					color: cd.fill,
				},
			});
		}

	});


};

interface UseBackwardsCompatibilityProps {
    canvasData?: CanvasData;
	thickness?: ThicknessType;
    colors: any;
}