import React, {useState} from 'react';
import {OrbitControls} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {MouthguardModel} from '@mgp-fe/designer/modules/common/designer/mouthguard-model.tsx';
import {ToggleButton} from '@mgp-fe/designer/modules/common/ui/components';

export function DesignerClassic() {

	const [isLocked, setIsLocked] = useState(false);

	/* eslint-disable */
	return <div className="relative md:mt-[-5rem] tall:mt-0 flex flex-col w-full">
		<div className="w-fit grid gap-4 h-fit mt-auto py-2 mx-auto z-[4]">
			<ToggleButton onCheckedChange={value => setIsLocked(value)}/>
		</div>
		<div
			className=" flex items-center w-full !h-[8rem] md:!h-[20vh] mx-auto">
			{isLocked && <div className="absolute w-full h-full z-[3] inset-0"/>}
			<Canvas gl={{preserveDrawingBuffer: true}} linear flat
					className="!h-[20rem] md:!h-[30rem] mx-auto cursor-grab active:cursor-grabbing">
				<ambientLight intensity={Math.PI / 2}/>
				<spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI}/>
				<pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI}/>
				<OrbitControls maxDistance={3.5} minDistance={2.8} enablePan={false}/>
				{/*<Stats showPanel={0} />*/}
				<MouthguardModel/>
			</Canvas>
		</div>
	</div>;
}