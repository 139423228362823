export default {
	index: '/',
	athleteDesigner: {
		index: 'athlete-designer',
		detail: 'athlete-designer/:id',
		detailLink: (id: string) => `athlete-designer/${id}`,
	},
	athleteTeamDesigner: {
		index: 'athlete-team-designer',
		detail: 'athlete-team-designer/:id',
		detailLink: (id: string) => `athlete-team-designer/${id}`,
	},
	teamDesigner: {
		index: 'team-designer',
		detail: 'team-designer/:id',
		detailLink: (id: string) => `team-designer/${id}`,
	},
	affiliateDesigner: {
		index: 'affiliate-designer',
		detail: 'affiliate-designer/:id',
		detailLink: (id: string) => `affiliate-designer/${id}`,
	},
	athleteAffiliateDesigner: {
		index: 'affiliate-athlete-designer',
		detail: 'affiliate-athlete-designer/:id',
		detailLink: (id: string) => `affiliate-athlete-designer/${id}`,
	},
	clearDesigner: {
		index: 'clear-designer',
		detail: 'clear-designer/:id',
		detailLink: (id: string) => `clear-designer/${id}`,
	},
};