import React, {useContext, useEffect, useRef, useState} from 'react';
import {cn} from '@mgp-fe/shared/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {ThicknessType} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@mgp-fe/shared/ui/tooltip.tsx';
import ThicknessSlider from '@mgp-fe/shared/modules/mouthguard-design/components/ThicknessSlider.tsx';

export function ThicknessDropdown() {
	const designerContext = useContext(DesignerContext);
	const [sliderValue, setSliderValue] = useState<number>(1);
	const showDropdown = useToggle({initialState: false});
	const buttonRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [fullHeight, setFullHeight] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [windowWidth, setWindowWidth] = useState(0);
	const [windowHeight, setWindowHeight] = useState(0);


	const thicknessInMillimeters = sliderValue === 2 ? '6 mm' : sliderValue === 1 ? '5 mm' : '4 mm';

	
	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		});
		setFullHeight((buttonRef.current?.offsetHeight ?? 0) + (dropdownRef.current?.offsetHeight ?? 0));
		return () => window.removeEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		});
	}, [showDropdown, windowWidth, windowHeight]);

	useEffect(() => {
		if (!designerContext.thickness.thickness) return;

		if (designerContext.thickness.thickness === 'thick')
			setSliderValue(2);
		if (designerContext.thickness.thickness === 'normal')
			setSliderValue(1);
		if (designerContext.thickness.thickness === 'thin')
			setSliderValue(0);
		setLoaded(true);
	}, [designerContext.thickness.thickness]);

	useEffect(() => {
		if (!loaded) return;

		let thickness: ThicknessType = 'normal';

		if (sliderValue === 2) thickness = 'thick';
		if (sliderValue === 1) thickness = 'normal';
		if (sliderValue === 0) thickness = 'thin';

		designerContext.dispatchThickness({
			thickness,
		});
	}, [sliderValue]);

	return <div className='relative w-40 sm:tall:w-64 ml-auto pointer-events-auto'>
		<div className='absolute inset-0'>
			<div className='flex justify-between mb-2 sm:tall:mb-4'>
				<span className='text-primary text-sm sm:tall:text-[18px]'>Thickness</span>
				<TooltipProvider>
					<Tooltip delayDuration={0}>
						<TooltipTrigger className='hidden md:block'>
							<FontAwesomeIcon icon='info-circle' fontVariant='far'/>
						</TooltipTrigger>
						<TooltipContent
							className='border-muted bg-background rounded-3xl sm:tall:rounded-[45px] w-64 px-4 py-8'>
							<p className='text-muted'>
								<span className='text-secondary'>Full custom GARD</span>
								&nbsp;- standard thickness is 5 mm but it is recommended to choose thickness based on
								use.
								For example 6 mm for high contact sport or 4 mm for non contact sports.
								(thickness is before thermoforming)
							</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
			<div
				style={{height: showDropdown.state ? fullHeight + 'px' : buttonRef.current?.offsetHeight + 'px'}}
				className={cn(
					'bg-background-500 border border-white rounded-3xl sm:tall:rounded-[45px] overflow-hidden transition-all duration-300',
				)}>
				<div
					onClick={() => showDropdown.toggle()}
					ref={buttonRef}
					className='flex items-center px-4 sm:tall:px-12 py-3 sm:tall:py-6 cursor-pointer justify-between'>
					<h5 className='text-muted text-[14px] sm:tall:text-xl'>{thicknessInMillimeters}</h5>
					<FontAwesomeIcon
						className='text-muted duration-300'
						icon='chevron-right'
						style={{rotate: showDropdown.state ? '90deg' : '0deg'}}/>
				</div>
				<div
					ref={dropdownRef}
					className='px-4 sm:tall:px-8 pb-3 sm:tall:pb-6'>
					<ThicknessSlider
						sliderValue={sliderValue}
						sliderCallback={value => {
							setLoaded(true);
							setSliderValue(value[0]);
						}}/>
				</div>
			</div>
		</div>
	</div>;
}