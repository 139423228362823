import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {
	CanvasData,
	MouthguardDesignModel,
	MouthguardDesignPublicAccess,
	MouthguardDesignType,
} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function useCreateMouthguardDesign(props?: UseCreateMouthguardDesignProps) {

	return useMutation<AxiosResponse<MouthguardDesignModel>, AxiosError<HydraError>, CreateMouthguardDesignRequest>({
		mutationKey: keysResolver(endpoints.mouthguardDesigns.list, 'post'),
		mutationFn: rq => coreApiClient.post<MouthguardDesignModel>(endpoints.mouthguardDesigns.list, rq),
		...props,
	});
}

type UseCreateMouthguardDesignProps = UseMutationOptions<
    AxiosResponse<MouthguardDesignModel>,
    AxiosError<HydraError>,
    CreateMouthguardDesignRequest
>

export interface CreateMouthguardDesignRequest {
    type: MouthguardDesignType;
    name: string;
    canvasData: CanvasData;
    colors: object;
    publicAccess: MouthguardDesignPublicAccess;
    team?: Iri;
    customer?: Iri;
    affiliate?: Iri;
    screenshot: string;
    texture: string;
}