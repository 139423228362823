import React, {useContext, useEffect, useState} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {ContentText} from '@mgp-fe/designer/state/domain';
import {AthleteControlButton, DesignerDialog} from '@mgp-fe/designer/modules/common/ui/components';
import {Button} from '@mgp-fe/shared/ui/button.tsx';

export function AthleteEditText({id, enabled}: AthleteEditTextProps) {
	const designerContext = useContext(DesignerContext);
	const displayModal = useToggle({initialState: false});
	const [originalContent, setOriginalContent] = useState<ContentText | undefined | null>(null);

	const controlledContent = designerContext.content[designerContext.content.findIndex(c => c.id === id)].text;

	useEffect(() => {
		if (!originalContent && displayModal.state) {
			setOriginalContent(controlledContent);
		}
	}, [displayModal.state]);

	const onCloseHandle = (isSave?: boolean) => {
		if (isSave) {
			setOriginalContent(controlledContent);
		} else {
			originalContent && designerContext.dispatchContent({
				id,
				content: originalContent,
			});
		}
		displayModal.off();
	};

	console.log(controlledContent);

	return <>
		<AthleteControlButton
			onClick={() => {
				if (enabled)
					displayModal.on();
			}}
			onRemove={enabled ? () => {
				designerContext.dispatchContent({
					id,
					content: {
						label: 'Player',
						type: 'text',
					},
				});
				setOriginalContent(null);
			} : undefined}
			controlledContent={controlledContent}/>

		<DesignerDialog isOpen={displayModal.state} onClose={() => onCloseHandle()} title='Text' titleIcon='a'>
			<div className='flex flex-col gap-16 md:px-[10rem] my-8'>
				<input
					onChange={(event) => {
						if (event.target.value) {
							designerContext.dispatchContent({
								id,
								content: {
									label: event.target.value,
									type: 'text',
								},
							});
						}
					}}
					maxLength={15}
					className='max-w-[32rem] w-full text-center !text-[28px] !text-secondary !font-semibold placeholder:text-[17px] placeholder:opacity-50'
					placeholder='Player name, nickname, number'
					type='text'
				/>
				<Button className='w-full ' onClick={() => onCloseHandle(true)}>
					Save
				</Button>
			</div>
		</DesignerDialog>
	</>;
}

interface AthleteEditTextProps {
	id: number;
	enabled?: boolean;
}