import React, {useEffect, useRef, useState} from 'react';
import {Slider} from '@mgp-fe/shared/ui/slider.tsx';
import {cn} from '@mgp-fe/shared/utils';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@mgp-fe/shared/ui/tooltip.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function ThicknessSlider({sliderValue, sliderCallback}: ThicknessSliderProps) {

	const sliderRef = useRef<HTMLDivElement>(null);
	const [windowWidth, setWindowWidth] = useState(0);
	const [windowHeight, setWindowHeight] = useState(0);
	const [fullHeight, setFullHeight] = useState(0);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		});
		setFullHeight(sliderRef.current?.offsetHeight ?? 0);
		return () => window.removeEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		});
	}, [windowWidth, windowHeight]);

	return <div className='flex gap-6'>
		<Slider
			max={2}
			step={1}
			defaultValue={[sliderValue]}
			value={[sliderValue]}
			onValueChange={value => sliderCallback(value)}
			orientation='vertical'
			className='h-[12.2rem] w-fit ml-auto cursor-pointer'
			style={{
				height: fullHeight,
			}}
		/>

		<div
			ref={sliderRef}
			className='flex flex-col h-fit gap-4 mr-auto'>
			<DropdownContent
				sliderValue={sliderValue}
				onClick={index => sliderCallback([index])}
				index={2}
				title={'6 mm'}
				infoText={'Thicker guards are great for high contact sport, and we find most boxing and martial arts athletes use the thicker design'}
				text={'for football, ice hockey, boxing ...'}/>
			<DropdownContent
				sliderValue={sliderValue}
				onClick={index => sliderCallback([index])}
				index={1}
				title={'5 mm'}
				infoText={'Normal thickness is for everyday use and is our most common thickness for most sports.'}
				text={'for soccer, handball, volleyball, ...'}/>
			<DropdownContent
				sliderValue={sliderValue}
				onClick={index => sliderCallback([index])}
				index={0}
				title={'4 mm'}
				infoText={'Thinner thickness is great for non contact sports, and great for practice.'}
				text={'for badminton, floorball, baseball, ...'}/>
		</div>
	</div>;
}

const DropdownContent = ({sliderValue, index, title, text, infoText, onClick}: DropdownContentProps) => <div
	onClick={() => onClick(index)}
	className='flex flex-col gap-1 cursor-pointer'>
	<div className='flex justify-between'>
		<p className={cn('text-muted duration-300 text-xl', sliderValue == index ? 'text-secondary' : '')}>
			{title}
		</p>
		<TooltipProvider>
			<Tooltip delayDuration={0}>
				<TooltipTrigger className='hidden md:block'>
					<FontAwesomeIcon icon='info-circle' fontVariant='far'/></TooltipTrigger>
				<TooltipContent className='border-muted bg-background rounded-[45px] w-64 px-4 py-8'>
					<p className='text-muted'>
						{infoText}
					</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	</div>
	<p className={cn('text-muted duration-300 text-[12px]', sliderValue == index ? 'text-secondary' : '')}>{text}</p>
</div>;

interface ThicknessSliderProps {
	sliderValue: number;
	sliderCallback: (value: number[]) => void;
}

interface DropdownContentProps {
	sliderValue: number;
	index: number;
	title: string;
	text: string;
	infoText: string;
	onClick: (index: number) => void;
}
