import React from 'react';
import {AIcon, TriangleDownIcon} from '@mgp-fe/designer/assets';
import RemoveContentButton from '@mgp-fe/designer/modules/common/ui/components/remove-content.tsx';
import {ContentText} from '@mgp-fe/designer/state/domain';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function TextControlButton({id, onClick, onRemove, controlledContent}: TextControlButtonProps) {
	return <div
		className='flex flex-col mx-auto w-32 h-32 lg:tall:w-40 lg:tall:h-40 relative select-none cursor-grab active:cursor-grabbing'>
		<TriangleDownIcon className='absolute h-full w-full z-[1]'/>
		<div
			onClick={onClick}
			className='flex flex-col w-1/2 h-1/2 m-auto z-[2] cursor-pointer'>
			{!controlledContent?.label ? <><
				h5 className='mx-auto text-muted z-[2] text-[1rem] lg:text-[1.2rem]'>TEXT</h5>
			<AIcon className='mx-auto mt-3 z-[2]'/>
			</> : <div className='flex flex-col mx-auto w-full h-full items-center'>
				<h5 className='mx-auto max-w-[5.4rem] text-muted z-[2] truncate break-all text-[1rem] lg:text-[1.2rem]'>{controlledContent?.label}</h5>
				<div className='h-4 w-4 z-[2] mt-6 rounded-full' style={{backgroundColor: controlledContent?.color}}/>
			</div>}

		</div>
		<div className='flex justify-end w-full cursor-pointer'>
			{controlledContent?.label && <FontAwesomeIcon
				className='mr-auto mt-auto z-[3] text-muted hover:text-primary duration-300'
				onClick={event => {
					event.stopPropagation();
					onClick();
				}}
				icon='pen'/>}
			{controlledContent?.label && <RemoveContentButton
				id={id}
				removeCallback={onRemove}
			/>}
		</div>
	</div>;
}

interface TextControlButtonProps {
	id: number;
	onClick: () => void;
	onRemove: () => void;
	controlledContent: ContentText | undefined;
}