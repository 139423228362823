import React from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';

export default function CircularSliderWrapper({onChange, initialValue}: CircularSliderWrapperProps) {

	return <CircularSlider
		min={0}
		max={360}
		direction={1}
		knobPosition={0}
		appendToValue='°'
		valueFontSize='1.5rem'
		trackColor='#eeeeee'
		width={150}
		trackDraggable={true}
		dataIndex={initialValue}
		onChange={(value: number) => onChange(value)}
		progressColorFrom='#ace600'
		progressColorTo='#4e992f'
		labelColor='#ace600'
		knobColor='#4e992f'
		verticalOffset='0rem'
	/>;
}

interface CircularSliderWrapperProps {
	onChange: (value: number) => void;
	initialValue: number;
}