import React, {useContext} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';

export default function RemoveContentButton({id, removeCallback}: RemoveContentButtonProps) {
	const designerContext = useContext(DesignerContext);

	return  <FontAwesomeIcon
		onClick={event => {
			event.stopPropagation();
			designerContext.dispatchContent({
				id,
				content: {
					type: 'undefined',
				},
			});
			removeCallback && removeCallback();
		}}
		className='ml-auto mt-auto z-[3] text-destructive-500 hover:text-destructive-800 duration-300'
		icon='trash-can'/>;
} 

interface RemoveContentButtonProps {
    id: number;
    removeCallback?: () => void;
}