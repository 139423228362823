import React, {useContext, useEffect, useState} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {DesignerDialog, ImageControlButton} from '@mgp-fe/designer/modules/common/ui/components';
import {ContentImage} from '@mgp-fe/designer/state/domain';
import ImageUploader from '@mgp-fe/designer/modules/common/ui/components/image-designer/image-uploader.tsx';
import ImageControls from '@mgp-fe/designer/modules/common/ui/components/image-designer/image-controls.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@mgp-fe/shared/ui/tooltip.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function ImageDesigner({id, modalState}: ImageDesignerProps) {
	const designerContext = useContext(DesignerContext);
	const displayAddImageModal = useToggle({initialState: false});
	const displayEditImageModal = useToggle({initialState: false});
	const [originalContent, setOriginalContent] = useState<ContentImage | undefined | null>(null);

	const controlledContent = designerContext.content[designerContext.content.findIndex(c => c.id === id)].image;

	useEffect(() => {
		if (!originalContent && (displayAddImageModal.state || displayEditImageModal)) {
			setOriginalContent(controlledContent);
		}
	}, [displayAddImageModal.state, displayEditImageModal.state]);

	const onCloseHandle = (isSave?: boolean) => {
		console.log(isSave);
		if (isSave) {
			setOriginalContent(controlledContent);
		} else if (originalContent) {
			designerContext.dispatchContent({
				id,
				content: originalContent,
			});
		} else {
			designerContext.dispatchContent({
				id,
				content: {
					type: 'undefined',
				},
			});
		}
		displayAddImageModal.off();
		displayEditImageModal.off();
		modalState(false);
	};


	return (
		<>
			<ImageControlButton
				id={id}
				onClick={() => {
					displayAddImageModal.on();
					modalState(true);
				}}
				onEdit={() => {
					displayEditImageModal.on();
					modalState(true);
				}}
				onRemove={() => setOriginalContent(null)}
				controlledContent={controlledContent}
			/>

			<DesignerDialog isOpen={displayAddImageModal.state} onClose={() => onCloseHandle()} title='Image'
				titleIcon='image'>
				<ImageUploader id={id} controlledContent={controlledContent}/>
				<div className='flex mt-8'>
					<Button className='w-fit mx-auto' onClick={() => onCloseHandle(true)}>
						Save
					</Button>
				</div>
			</DesignerDialog>

			<DesignerDialog isOpen={displayEditImageModal.state} onClose={() => onCloseHandle()} title='Edit Image'
				titleIcon='image'>
				<div className='hidden md:flex'>
					<TooltipProvider>
						<Tooltip delayDuration={0}>
							<TooltipTrigger className='ml-auto'><FontAwesomeIcon icon='info-circle' fontVariant='far'/></TooltipTrigger>
							<TooltipContent className='border-muted bg-background rounded-lg w-64 px-4 py-4'>
								<p className='text-muted'>
									Images & logos might look different on real product than in designer.
								</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<p className='sm:hidden text-muted/50 text-xs'>
					Images & logos might look different on real product than in designer.
				</p>
				<ImageControls id={id} controlledContent={controlledContent}/>
				<div className='flex mt-8'>
					<Button className='w-full mx-auto' onClick={() => onCloseHandle(true)}>
						Save
					</Button>
				</div>
			</DesignerDialog>
		</>
	);
}

interface ImageDesignerProps {
	id: number;
	modalState: (state: boolean) => void;
}