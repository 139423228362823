'use client';

import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {AUTH_LOCAL_STORAGE_KEY, HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {CreateCustomerMutationData} from '@mgp-fe/shared/core-api/mutations/user/customer/create.ts';
import {useGetRecaptchaToken} from '@mgp-fe/shared/hooks/useGetRecaptchaToken.ts';

export default function useUtilizeSignUpLinkMutation(props?: UseCreateCustomerFromSignUpLinkMutationProps) {
	const queryClient = useQueryClient();
	const [, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);
	const recaptchaToken = useGetRecaptchaToken('invite_link_signup');

	return useMutation<AxiosResponse<Customer>, AxiosError<HydraError>, CreateCustomerFromSignUpLinkMutationData>(
		keysResolver(endpoints.signUpLinks.createCustomer(''), 'post'),
		async (data) => coreApiClient.post<Customer>(
			endpoints.signUpLinks.createCustomer(data.id),
			{
				user: data.request,
                recaptchaToken: !data?.request.recaptchaToken ? await recaptchaToken() : '',
			},
		),
		{
			...props,
			onSuccess: async (result, variables, context) => {
				result.data.signToken && setToken(result.data.signToken);
				await queryClient.invalidateQueries(keysResolver(endpoints.me.index));
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

type UseCreateCustomerFromSignUpLinkMutationProps = UseMutationOptions<
	AxiosResponse<Customer>,
	AxiosError<HydraError>,
	CreateCustomerFromSignUpLinkMutationData
>;

export interface CreateCustomerFromSignUpLinkMutationData {
	id: string;
	request: {
		user?: CreateCustomerMutationData;
		recaptchaToken?: string;
	};
}