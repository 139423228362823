import React, {useContext} from 'react';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import AthleteContent from '@mgp-fe/designer/modules/common/ui/components/athlete-text/athlete-content.tsx';

export function AthleteTeamLayout() {
	const designerContext = useContext(DesignerContext);

	return <main className='container'>
		<div className='flex flex-row justify-between md:h-96 py-8 sm:px-8 gap-8'>
			<AthleteContent id={designerContext.content.findIndex(c => c.id === 0)}/>
			<AthleteContent id={designerContext.content.findIndex(c => c.id === 1)}/>
			<AthleteContent id={designerContext.content.findIndex(c => c.id === 2)}/>
		</div>
	</main>;
}