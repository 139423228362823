import {MutationKey, QueryKey} from '@tanstack/react-query';

export default function keysResolver(endpoint: string, method?: HttpMethod, params?: ParamsType): QueryKey | MutationKey {
	return [
		`core-api:query:${endpoint}`,
		method || 'get',
		...(params ? [params] : []),
	];
}

type ParamsType = string | number | boolean | never[] | string[] | Record<string, unknown> | object | null | undefined;
type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';