import {ScreenshotReducerActions, screenshotActionTypes, ScreenshotType} from '@mgp-fe/designer/state/domain';

export function screenshotReducer(state: ScreenshotType, action: ScreenshotReducerActions) {
	const {type, image} = action;
	switch (type){
	case screenshotActionTypes.TEXTURE:
		return {
			...state,
			textureScreenshot: image,
		};
	case screenshotActionTypes.SCENE:
		return {
			...state,
			sceneScreenshot: image,
		};
	default:
		return state;
	}
}