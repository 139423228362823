import {z, ZodEffects, ZodString} from 'zod';

export const passwordSchema = z.string().refine(
	v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(v),
	'Password must contain at least 6 characters, one uppercase letter and one number.',
);

export const onboardingPasswordSchema = (optional: boolean) => {
	let schema: ZodEffects<ZodString, string, string> = z.string().refine(
		v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(v) || optional,
		'Password must contain at least 6 characters, one uppercase letter and one number.',
	);

	return optional ? schema.optional() : schema;
}