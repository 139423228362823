import * as React from 'react';
import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '@mgp-fe/shared/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const buttonVariants = cva(
	'ui-button inline-flex gap-2 leading-none items-center justify-center rounded-full font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-70  transition-all duration-200 transform hover:scale-95',
	{
		variants: {
			variant: {
				default: 'rounded-full bg-primary text-primary-foreground hover:bg-primary-500/70',
				destructive: 'rounded-full bg-destructive text-destructive-foreground hover:bg-destructive/90',
				outline:
					'rounded-full border border-input bg-background text-accent hover:bg-accent hover:text-accent-foreground',
				secondary:
					'bg-secondary text-secondary-foreground hover:bg-secondary-500/50',
				ghost: 'hover:bg-accent hover:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
				linkDestructive: 'text-destructive underline-offset-4 hover:underline',
				linkWarning: 'text-warning normal-case underline-offset-4 hover:underline',
				linkSecondary: 'text-secondary underline-offset-4 hover:underline',
				linkMuted: 'text-muted underline-offset-4 hover:underline',
			},
			size: {
				default: 'px-10 py-5 button-default',
				sm: 'px-4 py-1 text-sm',
				md: 'px-6 md:px-8 py-2 text-lg',
				lg: 'px-8 md:px-10 py-3 md:py-4 text-lg md:text-xl',
				icon: 'h-10 w-10',
				iconMd: 'h-8 w-8',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	state?: 'idle' | 'loading' | 'success' | 'error';
	icon?: React.ReactNode;
	iconPosition?: 'left' | 'right';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({className, variant, size, icon, iconPosition = 'left', asChild = false, state = 'default', ...props}, ref) => {
		const Comp = asChild ? Slot : 'button';

		if (Comp === 'button') {
			return <button
				className={cn(
					buttonVariants({variant, size, className}),
					state === 'loading' ? 'animate-pulse-slow' : '',
				)}
				ref={ref}
				{...props} disabled={state === 'loading' || props.disabled || false}>
				{state === 'loading' ? <>
					{(size === 'icon' || size === 'iconMd')
						? <><FontAwesomeIcon icon='spinner' spin/></>
						: <>
							{iconPosition === 'left' ? <FontAwesomeIcon icon='spinner' spin/> : ''}
							Loading...
							{iconPosition === 'right' ? <FontAwesomeIcon icon='spinner' spin/> : ''}
						</>}
				</>
					: <>
						{iconPosition === 'left' ? icon : ''}
						{props.children}
						{iconPosition === 'right' ? icon : ''}
					</>}
			</button>;
		}

		return <Comp
			className={cn(buttonVariants({variant, size, className}))}
			ref={ref}
			{...props}
		/>;
	},
);
Button.displayName = 'Button';

export {Button, buttonVariants};