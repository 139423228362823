import {useMemo, useState} from 'react';

export default function useToggle({initialState = false}: { initialState?: boolean } | undefined = {}) {
	const [state, setState] = useState(initialState);

	const handlers = useMemo(() => ({
		on: () => setState(true),
		off: () => setState(false),
		toggle: () => setState((state) => !state),
	}), []);

	return {
		state,
		...handlers,
	};
}

export interface UseToggleResult {
	state: boolean;
	on: () => void;
	off: () => void;
	toggle: () => void;
}