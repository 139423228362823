export const MouthguardTextColors = [
	{ color: '#674595' },
	{ color: '#2664BC' },
	{ color: '#0F4A81' },
	{ color: '#9A131C' },
	{ color: '#EA281E' },
	{ color: '#000000' },
	{ color: '#FFFFFF' },
	{ color: '#8BEC4F' },
	{ color: '#E3E75E' },
	{ color: '#DAB44F' },
	{ color: '#EF7D3D' },
] as const;