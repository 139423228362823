import React, {useContext, useEffect} from 'react';
import {AthleteLayout, DesignerClassic, SaveDesign} from '@mgp-fe/designer/modules/common';
import useMouthguardDetailQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/detail.ts';
import {useParams} from 'react-router-dom';
import {useBackwardsCompatibility} from '@mgp-fe/designer/hooks';
import {useLoginWithToken} from '@mgp-fe/designer/hooks/login-with-token.ts';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import Container from '@mgp-fe/designer/modules/common/ui/layout/container.tsx';
import DesignerHeader from '@mgp-fe/designer/modules/common/ui/layout/designer-header.tsx';
import {MouthguardColors, MouthguardSections} from '@mgp-fe/designer/constants';

export function AthleteDesignerPage() {

	const {id} = useParams();
	const designerContext = useContext(DesignerContext);

	const design = useMouthguardDetailQuery({
		id: id ?? '',
	});

	useLoginWithToken();

	useBackwardsCompatibility({
		canvasData: design.data?.data.canvasData,
		colors: design.data?.data.colors,
		thickness: design.data?.data.thickness,
	});

	useEffect(() => {
		MouthguardSections.full.forEach(p => designerContext.dispatchSectionColors({
			section: p,
			color: MouthguardColors.find(c => c.name === 'Green')?.color ?? '',
		}));

		[0, 1, 2].forEach(p => designerContext.dispatchContent({
			id: designerContext.content[p].id,
			content: {
				type: 'undefined',
			},
		}));
	}, []);

	return <Container>
		<DesignerHeader/>
		<DesignerClassic/>
		<AthleteLayout/>
		<SaveDesign/>
	</Container>;
}

