'use client';

import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {
	LoginMutationData,
	LoginMutationDataSchema,
	LoginMutationResult,
} from '@mgp-fe/shared/core-api/mutations/auth/login';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AxiosError, AxiosResponse} from 'axios';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {AlertCircle} from 'lucide-react';
import {zodResolver} from '@hookform/resolvers/zod';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';

export default function LoginForm(props: LoginFormProps) {
	const {login, isUserLoading} = useAuth();
	const form = useForm<LoginMutationData>({
		resolver: zodResolver(LoginMutationDataSchema),
	});
	const [error, setError] = useState<string | undefined>();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (d: LoginMutationData) => {
		setLoading(true);
		props.submitData && props.submitData(d);
		try {
			const result = await login({...d, userType: props.appName});
			setError(undefined);
			props.onSuccess?.(result, d);
		} catch (error) {
			setError((error as AxiosError<{
				error: string
			}>)?.response?.data?.error || 'Bad credentials or account is not approved');
		} finally {
			setLoading(false);
		}
	};

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => onSubmit(d))}
			className='flex flex-col horizontal-labels w-full'>

			<TextInput label='E-mail' type='email' name='username'/>
			<TextInput label='Password' type='password' name='password'/>

			<Button className='mt-6' size='lg' type='submit' icon={<FontAwesomeIcon icon='arrow-right-to-bracket'/>}
				state={isUserLoading || loading ? 'loading' : 'idle'}>
				Login
			</Button>

			{error ? <Alert variant='destructive' className='mb-0'>
				<AlertTitle className='flex gap-2 items-center justify-start'><AlertCircle className='h-4 w-4'/>
					<span>Error</span></AlertTitle>
				<AlertDescription>
					{error || 'Unknown error'}
				</AlertDescription>
			</Alert> : ''}
		</form>
	</FormProvider>;
}

interface LoginFormProps {
	onSuccess?: (data: AxiosResponse<LoginMutationResult>, variables: LoginMutationData) => void;
	submitData?: (data: LoginMutationData) => void;
	appName?: string;
}