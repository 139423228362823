import {createContext} from 'react';
import {NotificationsContextState} from '@mgp-fe/shared/ui/notifications/domain.ts';

export default createContext<NotificationsContextState>({
	notifications: [],
	notify: () => undefined,
	notifySuccess: () => undefined,
	notifyInfo: () => undefined,
	notifyWarning: () => undefined,
	notifyError: () => undefined,
});