import React from 'react';
import {MouthguardModel} from '@mgp-fe/designer/modules/common/designer/mouthguard-model.tsx';
import {Canvas, Vector3} from '@react-three/fiber';
import {PerspectiveCamera} from '@react-three/drei';
import {Euler, MathUtils} from 'three';

export function DesignPreview({contentPosition}: DesignPreviewProps) {
	/* eslint-disable */

	const cameraSettings = [
		{
			position: [-3.5, 0, 0.7],
			rotation: [0, MathUtils.degToRad(-70), 0],
		}, {
			position: [0, 0.2, 3],
			rotation: [0, 0, 0],

		}, {
			position: [3.5, 0.2, 0.7],
			rotation: [0, MathUtils.degToRad(70), 0],
		}, {
			position: [0, 4, 0.7],
			rotation: [-1.5, 0, 0.05],
		},
	];

	return <Canvas gl={{preserveDrawingBuffer: true}} linear flat className="w-fit !h-[7rem] sm:!h-[10rem]">
		<ambientLight intensity={Math.PI / 2}/>
		<spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI}/>
		<pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI}/>
		<PerspectiveCamera
			resolution={400}
			makeDefault
			rotation={cameraSettings[contentPosition].rotation as never as Euler}
			// fov={20}
			position={cameraSettings[contentPosition].position as Vector3}
		/>
		<MouthguardModel/>
	</Canvas>;
}

interface DesignPreviewProps {
	contentPosition: number;
}