import React, {useEffect, useRef, useState} from 'react';
import {cn} from '@mgp-fe/shared/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {MouthguardDesignType} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import {Link} from 'react-router-dom';
import routes from '@mgp-fe/designer/router/routes.ts';

export function DesignerDropdown() {
	const [designType, setDesignType] = useState<MouthguardDesignType>('full_custom_design');
	const showDropdown = useToggle({initialState: false});
	const buttonRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [fullHeight, setFullHeight] = useState(0);
	const [windowWidth, setWindowWidth] = useState(0);
	const [windowHeight, setWindowHeight] = useState(0);


	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		});
		setFullHeight((buttonRef.current?.offsetHeight ?? 0) + (dropdownRef.current?.offsetHeight ?? 0));
		return () => window.removeEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		});
	}, [showDropdown, windowWidth, windowHeight]);


	useEffect(() => {
		if (window.location.pathname.includes(routes.athleteDesigner.index))
			setDesignType('full_custom_design');
		if (window.location.pathname.includes(routes.clearDesigner.index))
			setDesignType('clear_custom_design');
	}, [window.location.pathname]);


	return <div className='relative min-h-20 w-40 sm:tall:tall:w-64 mr-auto pointer-events-auto'>
		<div className='absolute inset-0'>
			<div className='flex justify-between mb-2 sm:tall:mb-4'>
				<span className='text-primary text-sm sm:tall:text-[18px]'>Type of Mouthguard</span>
			</div>
			<div
				style={{height: showDropdown.state ? fullHeight + 'px' : buttonRef.current?.offsetHeight + 'px'}}
				className={cn(
					'bg-background border border-white rounded-3xl sm:tall:rounded-[45px] overflow-hidden transition-all duration-300',
				)}>
				<div
					onClick={() => showDropdown.toggle()}
					ref={buttonRef}
					className='flex items-center px-4 sm:tall:px-12 py-3 sm:tall:py-6 cursor-pointer justify-between'>
					<h5 className='text-muted text-[14px] sm:tall:text-xl'>{humanizeEnumValue(designType.replace('_design', ''))}</h5>
					<FontAwesomeIcon
						className='text-muted text-[14px] sm:tall:text-xl duration-300'
						icon='chevron-right'
						style={{rotate: showDropdown.state ? '90deg' : '0deg'}}/>
				</div>
				<div
					ref={dropdownRef}
					className='flex px-8 pb-3 sm:tall:pb-6 gap-6'>
					<div className='flex flex-col gap-4 mr-auto'>
						<DropdownContent
							linkTo={'/' + routes.athleteDesigner.index}
							designType={designType}
							type={'full_custom_design'}
							title={'Full Custom'}
							text={'Change colors and add text / image on the front & both sides.'}/>
						<div className='w-full h-[1px] bg-muted'/>
						<DropdownContent
							linkTo={'/' + routes.clearDesigner.index}
							designType={designType}
							type={'clear_custom_design'}
							title={'Clear Custom'}
							text={'Change colors and add text / image only on the front, both sides are transparent '}/>
					</div>
				</div>
			</div>
		</div>
	</div>;
}

const DropdownContent = ({designType, type, title, text, linkTo}: DropdownContentProps) => <Link
	to={linkTo}
	className='relative flex flex-col w-full ml-auto gap-1'>
	<FontAwesomeIcon icon='check' className={cn(
		'absolute top-1.5 left-[-1.5rem] text-secondary',
		designType !== type ? 'hidden' : '',
	)}/>
	<p className={cn('text-muted duration-300 text-[14px] sm:tall:text-xl font-semibold', designType === type ? 'text-secondary' : '')}>{title}</p>
	<p className={cn('text-muted duration-300 text-[12px]', designType === type ? 'text-secondary' : '')}>{text}</p>
</Link>;

interface DropdownContentProps {
	designType: MouthguardDesignType;
	type: MouthguardDesignType;
	title: string;
	text: string;
	linkTo: string;
}